export enum Urls {
  Homepage = "/",
  HolyPlace = "/holy-places",
  SMHomepage = "/sahaja-material",
  Founder = "/founder",
  FreeClasses = "/free-classes",
  ContactUs = "/contact-us",
  Events = "/events",
  Shop = "/sahaja-material/shop",
  ProductDetails = "/sahaja-material/product",
  Cart = "/sahaja-material/cart",
  Checkout = "/sahaja-material/checkout",
  Donation = "https://thelifeeternaltrustpune.org/donation/",
}

export const Storage = {
  TOKEN: "token",
  CART: "cart",
};

export const SOCIALS = {
  FaceBook: "https://www.facebook.com/nirmalnagaribhugaon",
  Youtube: "https://www.youtube.com/c/TheLifeEternalTrustPune",
  WhatsApp: "https://wa.me/+919168753232",
};
