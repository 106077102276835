import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { removeSessionStorage } from "../../utils/sessionStorage";
import { Urls } from "../../interfaces/constants";
import PrimaryButton from "../../components/common/Button/PrimaryButton";
import PrimarySquareButton from "../../components/common/Button/PrimarySquareButton";
import {
  Address,
  createAddress,
  Product,
  Order,
  createOrder,
  verifyOrder,
  VerifyOrder,
} from "../../services/let-data-services";
import { Grid } from "@material-ui/core";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

type Props = {};

function Verify({}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [fname, setFname] = useState<string>("");
  const [orderId, setOrderId] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [sverifyOrder, setVerifyOrder] = useState<VerifyOrder>(null);
  const [verifyOrderError, setVerifyOrderError] = useState<string>("");
  const [verifyOrderLoading, setVerifyOrderLoading] = useState<boolean>(false);
  const [isDataLoaded, setDataLoaded] = useState<boolean>(false);
  const params = new URLSearchParams(location.search);
  useEffect(() => {
    const _orderId = params.get("order_id");
    const _orderToken = params.get("order_token");

    if (_orderId && _orderToken) {
      setOrderId(_orderId);

      verifyOrder(
        _orderId,
        _orderToken,
        setVerifyOrder,
        setVerifyOrderLoading,
        setVerifyOrderError
      );
    }
  }, []);
  useEffect(() => {
    if (sverifyOrder) {
      setStatus(sverifyOrder.status);
      setDataLoaded(true);
    }
  }, [sverifyOrder]);

  return (
    <Grid container justifyContent="center">
      {isDataLoaded ? (
        status === "PAID" ? (
          <Box style={{ paddingTop: "5%", paddingBottom: "5%" }}>
            <Typography variant="h2" align="center">
              <CheckCircleOutlineIcon fontSize="large"></CheckCircleOutlineIcon>
            </Typography>
            <Typography variant="h3" align="center">
              JAI SHREE MATAJI
            </Typography>
            <Typography variant="h4" align="center">
              YOUR ORDER HAS BEEN RECEIVED
            </Typography>
            <Typography variant="h5" align="center">
              Order Id: {sverifyOrder.id}
            </Typography>
            <br></br>
            <Typography variant="h6" align="center">
              Your order will be delivered very soon! You will receive a
              confirmation email along with the receipt.
            </Typography>
            <Typography variant="h6" align="center">
              For any queries you may contact on +91 9168763232
            </Typography>
            <Typography variant="h6" align="center">
              or write to us on
            </Typography>
            <Typography variant="h6" align="center">
              sahaja-material@thelifeeternaltrustpune.org
            </Typography>
          </Box>
        ) : (
          <Box style={{ paddingTop: "5%", paddingBottom: "5%" }}>
            <Typography variant="h2" align="center">
              <CancelIcon fontSize="large"></CancelIcon>
            </Typography>
            <Typography variant="h3" align="center">
              JAI SHREE MATAJI
            </Typography>
            <Typography variant="h4" align="center">
              ERROR WHILE PLACING YOUR ORDER
            </Typography>
            <Typography variant="h5" align="center">
              Order Id: {orderId}
            </Typography>
            <br></br>
            <Typography variant="h6" align="center">
              There was an error while placing your order. If any amount was
              debited it will be refunded, please wait for few hours before
              placing the order again.
            </Typography>
            <Typography variant="h6" align="center">
              For any queries you may contact on +91 9168763232 or us on
              nirmalsahityasales@gmail.com
            </Typography>
          </Box>
        )
      ) : (
        <CircularProgress
          size="10rem"
          style={{ paddingTop: "10%", paddingBottom: "10%" }}
        ></CircularProgress>
      )}
    </Grid>
  );
}

export default Verify;
