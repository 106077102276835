import React from "react";
import { Box, createTheme } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
const weeklyCenterImage: { label: string; imgPath: string } = {
  label: "WeeklyCenter",
  imgPath: "images/Weeklycenters.png",
};
const useStyles = makeStyles((theme) => ({
  box: {
    width: "80%",
    margin: "auto",
    background: "#8E0024",
    borderRadius: "0% 0% 60px 60px",
  },
  text: {
    color: "#FFCB78",
    textTransform: "none",
  },
  content: {
    margin: "auto",
    width: "80%",
  },
}));

const WeeklyCenter = () => {
  const { t } = useTranslation();
  const theme = createTheme();
  const classes = useStyles(theme);

  return (
    <Box>
      <Box
        component="img"
        sx={{ width: 1 }}
        src={require("../../assets/WeeklyCenterNew.jpeg")}
        alt={weeklyCenterImage.label}
      />
    </Box>
  );
};

export default WeeklyCenter;
