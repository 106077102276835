import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { HolyPlaceModel } from "../../interfaces/interfaces";
import { appTheme } from "../../styles/theme";
import { useTranslation } from "react-i18next";

type Props = {
  selectedHolyPlace: HolyPlaceModel;
};

const useStyles = makeStyles({
  locationTabContainer: {
    display: "flex",
    margin: `${appTheme.spacing(4)}`,
    height: "100%",
  },
  addressContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: `${appTheme.spacing(4)}`,
  },
  mapContainer: {
    flex: 1,
  },
  row: {
    marginBottom: appTheme.spacing(4),
  },
  map: {
    border: "0px",
  },
  externalLink: {
    display: "inline-flex",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
      color: appTheme.palette.primary.main,
    },
  },
});

function LocationTab({ selectedHolyPlace }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.locationTabContainer}>
      <Box className={classes.addressContainer}>
        {selectedHolyPlace?.location?.address && (
          <Box className={classes.row}>
            <Typography variant="subtitle2">
              {t("holyPlaces.address")}
            </Typography>
            <Typography>{selectedHolyPlace?.location?.address}</Typography>
          </Box>
        )}

        {selectedHolyPlace?.location?.contact && (
          <Box className={classes.row}>
            <Typography variant="subtitle2">
              {t("holyPlaces.contact")}
            </Typography>
            <Typography>{selectedHolyPlace?.location?.contact}</Typography>
          </Box>
        )}

        {selectedHolyPlace?.location?.nearestRailwayStation && (
          <Box className={classes.row}>
            <Typography variant="subtitle2">
              {t("holyPlaces.nearestRailwayStation")}
            </Typography>
            <Typography>
              {selectedHolyPlace?.location?.nearestRailwayStation}
            </Typography>
          </Box>
        )}

        {selectedHolyPlace?.location?.nearestAirport && (
          <Box className={classes.row}>
            <Typography variant="subtitle2">
              {t("holyPlaces.nearestInternationAirport")}
            </Typography>
            <Typography>
              {selectedHolyPlace?.location?.nearestAirport}
            </Typography>
          </Box>
        )}

        {selectedHolyPlace?.location?.website && (
          <Box className={classes.row}>
            <Typography variant="subtitle2">
              {t("holyPlaces.website")}
            </Typography>
            <Typography
              className={classes.externalLink}
              onClick={() => window.open(selectedHolyPlace?.location?.website)}
            >
              {selectedHolyPlace?.location?.website.replace("https://", "")}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className={classes.mapContainer}>
        <iframe
          src={selectedHolyPlace?.location?.mapUrl}
          width="100%"
          height={"100%"}
          loading="lazy"
          className={classes.map}
        />
      </Box>
    </Box>
  );
}

export default LocationTab;
