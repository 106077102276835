import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import ImageCarousel from "../components/ImageCarousel";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { appTheme } from "../styles/theme";
import PrimaryButton from "../components/common/Button/PrimaryButton";
import { Urls } from "../interfaces/constants";
import { useNavigate } from "react-router-dom";
import { fetchPujas, Puja } from "../services/let-data-services";

const images: { label: string; imgPath: string }[] = [
  {
    label: "Image1",
    imgPath: "images/homepage-img-1.png",
  },
  {
    label: "Image2",
    imgPath: "images/homepage-img-1.png",
  },
  {
    label: "Image3",
    imgPath: "images/homepage-img-1.png",
  },
];
const image: { label: string; imgPath: string } = {
  label: "Image1",
  imgPath: "images/homepage-img-2.png",
};

const useStyles = makeStyles({
  primaryButtonMargin: {
    marginTop: appTheme.spacing(4),
    marginBottom: appTheme.spacing(12),
  },
  learnMoreContainer: {
    position: "absolute",
    bottom: "15%",
  },
  homeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  holyPlaceContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    margin: `${appTheme.spacing(4)} 
      ${appTheme.spacing(4)}
      ${appTheme.spacing(2)} 
      ${appTheme.spacing(4)}`,
  },
  aboutContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  imgAboutContainer: {
    marginBottom: appTheme.spacing(8),
  },
  upcomingEventsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const Home = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [pujas, setPujas] = useState<Puja[]>([]);
  const [pujasLoading, setPujasLoading] = useState(false);
  const [pujasError, setPujasError] = useState(null);

  useEffect(() => {
    fetchPujas(setPujas, setPujasLoading, setPujasError);
  }, []);

  return (
    <Box className={classes.homeContainer}>
      {/* carousel section */}

      <Box
        component="img"
        sx={isMobile ? { width: "100%" } : { width: "100%" }}
        src={require("../assets/homepage-img-1.png")}
        alt={image.label}
      />
      {/* holy place section */}
      <Box className={classes.holyPlaceContainer}>
        <Box
          component="img"
          sx={isMobile ? { width: "100%" } : { width: "80%" }}
          src={require("../assets/homepage-img-2.png")}
          alt={image.label}
        />
        <Box sx={{ mx: theme.spacing(2) }}>
          <Typography variant="subtitle1" fontSize={isMobile ? 14 : 16}>
            {t("dashboard.subtitle1")}
          </Typography>
          <Typography variant="subtitle1" fontSize={isMobile ? 14 : 16}>
            {t("dashboard.subtitle2")}
          </Typography>
          <Typography variant="subtitle2" fontSize={isMobile ? 14 : 16}>
            {t("dashboard.subtitle3")}
          </Typography>
        </Box>
        <PrimaryButton
          className={classes.primaryButtonMargin}
          onClick={() => navigate(Urls.HolyPlace)}
        >
          {t("dashboard.holyPlaces")}
        </PrimaryButton>
      </Box>
      {/* about section */}
      <Box className={classes.aboutContainer}>
        <Box
          className={classes.imgAboutContainer}
          component="img"
          sx={{ width: 1 }}
          src={require("../assets/about_section.png")}
          alt={image.label}
        />
      </Box>
      {/* upcoming events */}
      <Box className={classes.upcomingEventsContainer}>
        <Typography variant={isMobile ? "subtitle1" : "h5"}>
          {t("Upcoming Events")}
        </Typography>
        <Box
          component="img"
          sx={{ width: "70%", my: appTheme.spacing(4) }}
          src={
            pujas?.find(
              (puja) =>
                new Date() < new Date(puja.puja_date) && puja.type === "puja"
            )?.image
          }
          alt={image.label}
        />
        <Box
          component="img"
          sx={{ width: "20%", pb: "2%" }}
          src={require("../assets/separator.png")}
          alt={image.label}
        />
      </Box>
    </Box>
  );
};

export default Home;
