import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import {
  fetchProductsByCategory,
  fetchProductsById,
  fetchSMCategories,
  fetchProduct,
  Product,
  Puja,
} from "../../services/let-data-services";
import { appTheme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { useAsyncError, useLocation, useNavigate } from "react-router-dom";
import { Storage, Urls } from "../../interfaces/constants";
import PrimarySquareButton from "../../components/common/Button/PrimarySquareButton";
import Notification, {
  AlertColor,
} from "../../components/common/Notification/Notification";
import {
  getObjectSessionStorage,
  setObjectSessionStorage,
} from "../../utils/sessionStorage";

const useStyles = makeStyles({
  productPage: {
    margin: `${appTheme.spacing(8)} ${appTheme.spacing(12)}`,
  },
  mobileProductPage: {
    margin: `${appTheme.spacing(4)} ${appTheme.spacing(4)}`,
  },
  divider: {
    border: `1px solid ${appTheme.palette.divider}`,
    marginTop: appTheme.spacing(4),
    marginBottom: appTheme.spacing(4),
  },
  productDetailsContainer: {
    display: "flex",
    margin: `${appTheme.spacing(8)} ${appTheme.spacing(12)}`,
  },
  mobileProductDetailsContainer: {},
  buyButton: {
    marginLeft: appTheme.spacing(4),
  },
  categoryToolbar: {
    display: "flex",
    alignItems: "flex-start",
  },
  categoriesDropdown: {
    width: "30%",
  },
  mobileCategoriesDropdown: {
    width: "55%",
  },
  productContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  productCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    margin: appTheme.spacing(2),
    borderRadius: 5,
  },
  mobileProductCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "44%",
    margin: appTheme.spacing(2),
    borderRadius: 5,
  },
  viewButton: {
    margin: appTheme.spacing(4),
  },
});

type Props = {};

const ProductDetails = ({}: Props) => {
  //Make this false to disable store
  const [storeEnabled, setStoreEnabled] = useState<boolean>(true);

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const clx = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [product, setProduct] = useState<Product>(null);
  const [productLoading, setProductLoading] = useState(false);
  const [productError, setProductError] = useState(null);

  const [selectedVariation, setSelectedVariation] = useState<Product>(null);
  const [selectedCategory, setSelectedCategory] = useState<Puja>(null);
  const [categoriesMenuOptions, setCategoriesMenuOptions] = useState<Puja[]>(
    []
  );
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categoriesError, setCategoriesError] = useState(null);

  const [products, setProducts] = useState<Product[]>(null);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsError, setProductsError] = useState(null);

  const [quantity, setQuantity] = useState<number>(1);
  const [notification, setNotification] = useState<{
    title: string;
    severity: AlertColor;
  }>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [cart, setCart] = useState<Product[]>([]);
  const [availableQty, setAvailableQty] = useState<number>(0);
  const [checker, setChecker] = useState<boolean>(false);

  useEffect(() => {
    if (selectedVariation) {
      setAvailableQty(parseInt(selectedVariation.available_qty));
      setChecker(!checker);
    }
  }, [selectedVariation]);

  useEffect(() => {
    if (product) {
      if (product.type === "single") {
        setAvailableQty(parseInt(product.available_qty));
        setChecker(!checker);
      }
    }
  }, [product]);

  useEffect(() => {
    const loadCart = getObjectSessionStorage(Storage.CART);
    loadCart && setCart(loadCart);
  }, []);

  useEffect(() => {
    if (!selectedVariation && product) {
      setSelectedVariation(product.variations ? product.variations[0] : null);
    }
  }, [product]);

  useEffect(() => {
    if (product && !products && categoriesMenuOptions) {
      fetchProductsByCategory(
        product.category_name,
        setProducts,
        setProductsLoading,
        setProductsError
      );
      const _selectedCategory = categoriesMenuOptions.find(
        (option) => option.cat_name === product.category_name
      );
      _selectedCategory && setSelectedCategory(_selectedCategory);
    }
  }, [product, categoriesMenuOptions]);

  useEffect(() => {
    if (location?.state) {
      setProduct(location?.state.product);
      setSelectedCategory(location.state.selectedCategory);
      setCategoriesMenuOptions(location.state.categories);
      setProducts(location.state.products);
    } else {
      const productId = location?.pathname?.split("/")[3];
      if (productId) {
        fetchProductsById(
          productId,
          setProduct,
          setProductLoading,
          setProductError
        );
        fetchSMCategories(
          setCategoriesMenuOptions,
          setCategoriesLoading,
          setCategoriesError
        );
      }
    }
  }, [location]);

  const handleChange = (event: SelectChangeEvent) => {
    if (selectedCategory && categoriesMenuOptions) {
      const _selectedCategory = categoriesMenuOptions.find(
        (option) => option.cat_name === event.target.value
      );
      if (_selectedCategory) {
        navigate(Urls.Shop, {
          state: {
            selectedCategory: _selectedCategory,
            categories: categoriesMenuOptions,
          },
        });
      }
    }
  };

  const handleChangeVariation = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (product) {
        const selectedVar = product.variations.find(
          (prod) => prod.variation_value === event.target.value
        );
        selectedVar && setSelectedVariation(selectedVar);
      }
    },
    [product]
  );

  const handleViewProduct = (product: Product) => {
    if (product && selectedCategory && categoriesMenuOptions && products) {
      setSelectedVariation(null);
      navigate(Urls.ProductDetails.concat(`/${product.pid}`), {
        state: {
          product,
          products,
          selectedCategory: selectedCategory,
          categories: categoriesMenuOptions,
        },
      });
      window.scrollTo(0, 0);
    }
  };

  const handleQuantityChange = useCallback((event) => {
    const _quantity: number = event.target.value;

    if (_quantity > 0) setQuantity(_quantity);
  }, []);

  const handleAddToCart = useCallback(
    (product: Product) => {
      if (quantity > 0) {
        let isSelectedQtyLessThanAvailable = true;

        const _cart = Object.assign([], cart);
        const _selectedProduct = selectedVariation
          ? cart.find(
              (prod) =>
                prod.pid === product.pid && prod.vid === selectedVariation.vid
            )
          : cart.find((prod) => prod.pid === product.pid);
        if (
          (_selectedProduct && !selectedVariation) ||
          (_selectedProduct &&
            selectedVariation &&
            _selectedProduct.vid === selectedVariation.vid)
        ) {
          const _updatedQty = Number(_selectedProduct.qty) + Number(quantity);

          if (_selectedProduct.type === "variable") {
            if (_updatedQty > parseInt(selectedVariation.available_qty))
              isSelectedQtyLessThanAvailable = false;
          } else {
            if (_updatedQty > parseInt(_selectedProduct.available_qty))
              isSelectedQtyLessThanAvailable = false;
          }
          if (isSelectedQtyLessThanAvailable)
            _selectedProduct.qty = _updatedQty.toString();
          else {
            if (_selectedProduct.type === "variable") {
              setNotification({
                title:
                  "Only " +
                  selectedVariation.available_qty +
                  " units available of the selected product",
                severity: "warning",
              });
              setOpen(true);
            } else {
              setNotification({
                title:
                  "Only " +
                  _selectedProduct.available_qty +
                  " units available of the selected product",
                severity: "warning",
              });
              setOpen(true);
            }
          }
        } else {
          isSelectedQtyLessThanAvailable = true;
          const _newProduct = Object.assign({}, product);

          _newProduct.qty = quantity.toString();

          if (_newProduct.type === "variable") {
            if (quantity > parseInt(selectedVariation.available_qty))
              isSelectedQtyLessThanAvailable = false;
          } else {
            if (quantity > parseInt(_newProduct.available_qty))
              isSelectedQtyLessThanAvailable = false;
          }

          if (selectedVariation) {
            _newProduct.vid = selectedVariation.vid;
            _newProduct.price = selectedVariation.price;
          }
          if (isSelectedQtyLessThanAvailable) {
            _cart.push(_newProduct);
          } else {
            if (_newProduct.type === "variable") {
              setNotification({
                title:
                  "Only " +
                  selectedVariation.available_qty +
                  " units available of the selected product",
                severity: "warning",
              });
              setOpen(true);
            } else {
              setNotification({
                title:
                  "Only " +
                  _newProduct.available_qty +
                  " units available of the selected product",
                severity: "warning",
              });
              setOpen(true);
            }
          }
        }
        setCart(_cart);
        setObjectSessionStorage(Storage.CART, _cart);
        if (isSelectedQtyLessThanAvailable) {
          setNotification({
            title: "Added to Cart",
            severity: "success",
          });
          setOpen(true);
        }
      } else {
        setNotification({
          title: "Please set quantity",
          severity: "warning",
        });
        setOpen(true);
        setQuantity(1);
      }
    },
    [cart, quantity, selectedVariation]
  );

  useEffect(() => {
    if (product?.variations) {
      if (product.variations.length > 0) {
        setSelectedVariation(product.variations[0]);
        setAvailableQty(parseInt(product.variations[0].available_qty));
      }
    }
  }, [product]);

  return (
    <Box className={isMobile ? clx.mobileProductPage : clx.productPage}>
      {notification && (
        <Notification
          open={open}
          setOpen={setOpen}
          severity={notification.severity}
        >
          {notification.title}
        </Notification>
      )}
      <Box className={clx.categoryToolbar}>
        <FormControl
          className={
            isMobile ? clx.mobileCategoriesDropdown : clx.categoriesDropdown
          }
        >
          <InputLabel>Categories</InputLabel>
          <Select
            value={selectedCategory ? selectedCategory.cat_name : ""}
            label="Categories"
            onChange={handleChange}
          >
            {categoriesMenuOptions?.map((category: Puja) => (
              <MenuItem key={category.id} value={category.cat_name}>
                {category.cat_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className={clx.divider} />
      <Box
        className={
          isMobile
            ? clx.mobileProductDetailsContainer
            : clx.productDetailsContainer
        }
      >
        <Box
          component="img"
          sx={
            isMobile
              ? { width: 1, height: "10rem", objectFit: "contain", flex: 1 }
              : { width: 1, height: "20rem", objectFit: "contain", flex: 1 }
          }
          src={
            selectedVariation
              ? product?.variations.find(
                  (vProd) =>
                    vProd.variation_value === selectedVariation.variation_value
                ).image
              : product?.image
          }
          alt={product?.pname}
        />
        {!isMobile && <Box className={clx.divider} />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: appTheme.spacing(4),
            justifyContent: "space-around",
          }}
        >
          <Box>
            <Typography variant="h4">{product?.pname}</Typography>
            <Typography variant="h5" sx={{ my: appTheme.spacing(4) }}>
              &#8377;&nbsp;
              {selectedVariation
                ? product?.variations.find(
                    (vProd) =>
                      vProd.variation_value ===
                      selectedVariation.variation_value
                  ).price
                : product?.price}
            </Typography>
            {product?.variations && (
              <Box>
                <FormControl>
                  <FormLabel>{product.variations[0].variation_name}</FormLabel>
                  <RadioGroup
                    row
                    value={
                      selectedVariation
                        ? selectedVariation.variation_value
                        : product.variations[0].variation_value
                    }
                    onChange={handleChangeVariation}
                    defaultValue={product.variations[0].variation_value}
                  >
                    {product.variations.map((variation) => (
                      <FormControlLabel
                        key={variation.variation_value}
                        value={variation.variation_value}
                        control={<Radio />}
                        label={variation.variation_value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            <TextField
              label="Quantity"
              type="number"
              sx={{ my: appTheme.spacing(4), width: "12rem" }}
              value={quantity}
              onChange={handleQuantityChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {storeEnabled ? (
              <Box>
                <PrimarySquareButton onClick={() => handleAddToCart(product)}>
                  {t("product.addToCart")}
                </PrimarySquareButton>
                <PrimarySquareButton
                  className={clx.buyButton}
                  onClick={() =>
                    navigate(Urls.Cart, {
                      state: {
                        originalPath: location.pathname,
                      },
                    })
                  }
                >
                  {t("product.buyNow")}
                </PrimarySquareButton>
              </Box>
            ) : (
              "Jai Shree Mataji! Due to Ganpatipule Seminar we are not accepting orders till 10/01/2023"
            )}
          </Box>
        </Box>
      </Box>
      <Box className={clx.divider} />
      <Box>
        <Typography variant="h5" sx={{ my: appTheme.spacing(4) }}>
          Related products
        </Typography>
        <Box className={clx.productContainer}>
          {products
            ?.filter((prod) => prod.pid !== product.pid)
            .map((product, index) => (
              <Paper
                className={isMobile ? clx.mobileProductCard : clx.productCard}
                elevation={4}
                key={index}
              >
                <Box
                  component="img"
                  sx={
                    isMobile
                      ? { width: 1, height: "10rem", objectFit: "contain" }
                      : { width: 1, height: "20rem", objectFit: "contain" }
                  }
                  src={product.image}
                  alt={product.pname}
                />
                <Typography
                  textAlign="center"
                  variant={isMobile ? "subtitle2" : "h6"}
                >
                  {product.pname}
                </Typography>
                <PrimarySquareButton
                  className={clx.viewButton}
                  onClick={() => handleViewProduct(product)}
                >
                  {t("smHome.view")}
                </PrimarySquareButton>
              </Paper>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductDetails;
