import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import { Modal, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { appTheme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../components/common/Accordian/Accordian";

const useStyles = makeStyles({
  holyPlaceModal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    boxShadow: "24",
    borderRadius: 5,
    width: "100%",
    height: "60%",
    // overflowY: "scroll",
    overflow: "auto",
  },
  addressContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: `${appTheme.spacing(4)}`,
  },
  mapContainer: {
    flex: 1,
  },
  row: {
    marginBottom: appTheme.spacing(4),
  },
  map: {
    border: "0px",
  },
  externalLink: {
    display: "inline-flex",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
      color: appTheme.palette.primary.main,
    },
  },
});

const MobileHolyPlaceModal = (props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const classes = useStyles();
  const { open, setOpen, selectedHolyPlace } = props;
  const modalTabs: string[] = [
    "holyPlaces.information",
    "holyPlaces.significance",
    "holyPlaces.location",
  ];
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const renderInformationTab = useMemo(() => {
    if (selectedHolyPlace) {
      return (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary id={t(modalTabs[0])}>
            <Typography variant="subtitle2">{t(modalTabs[0])}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Typography variant="h3">{selectedHolyPlace.name1}</Typography>
              <Typography variant="h3">{selectedHolyPlace.name2}</Typography>
              <Box component={"br"} />
              {selectedHolyPlace.description.split("|").map((desc) => (
                <>
                  <Typography>{desc}</Typography>
                  <Box component={"br"} />
                </>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    }
  }, [expanded, selectedHolyPlace]);

  const renderSignificanceTab = useMemo(() => {
    if (selectedHolyPlace) {
      return (
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary id={t(modalTabs[1])}>
            <Typography variant="subtitle2">{t(modalTabs[1])}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {selectedHolyPlace.significance.split("|").map((desc) => (
                <>
                  <Typography>{desc}</Typography>
                  <Box component={"br"} />
                </>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    }
  }, [expanded, selectedHolyPlace]);

  const renderLocationTab = useMemo(() => {
    if (selectedHolyPlace) {
      return (
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary id={t(modalTabs[2])}>
            <Typography variant="subtitle2">{t(modalTabs[2])}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Box className={classes.addressContainer}>
                {selectedHolyPlace?.location?.address && (
                  <Box className={classes.row}>
                    <Typography variant="subtitle2">
                      {t("holyPlaces.address")}
                    </Typography>
                    <Typography>
                      {selectedHolyPlace?.location?.address}
                    </Typography>
                  </Box>
                )}

                {selectedHolyPlace?.location?.contact && (
                  <Box className={classes.row}>
                    <Typography variant="subtitle2">
                      {t("holyPlaces.contact")}
                    </Typography>
                    <Typography>
                      {selectedHolyPlace?.location?.contact}
                    </Typography>
                  </Box>
                )}

                {selectedHolyPlace?.location?.nearestRailwayStation && (
                  <Box className={classes.row}>
                    <Typography variant="subtitle2">
                      {t("holyPlaces.nearestRailwayStation")}
                    </Typography>
                    <Typography>
                      {selectedHolyPlace?.location?.nearestRailwayStation}
                    </Typography>
                  </Box>
                )}

                {selectedHolyPlace?.location?.nearestAirport && (
                  <Box className={classes.row}>
                    <Typography variant="subtitle2">
                      {t("holyPlaces.nearestInternationAirport")}
                    </Typography>
                    <Typography>
                      {selectedHolyPlace?.location?.nearestAirport}
                    </Typography>
                  </Box>
                )}

                {selectedHolyPlace?.location?.website && (
                  <Box className={classes.row}>
                    <Typography variant="subtitle2">
                      {t("holyPlaces.website")}
                    </Typography>
                    <Typography
                      className={classes.externalLink}
                      onClick={() =>
                        window.open(selectedHolyPlace?.location?.website)
                      }
                    >
                      {selectedHolyPlace?.location?.website.replace(
                        "https://",
                        ""
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box className={classes.mapContainer}>
                <iframe
                  src={selectedHolyPlace?.location?.mapUrl}
                  width="100%"
                  height={"100%"}
                  loading="lazy"
                  className={classes.map}
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    }
  }, [expanded, selectedHolyPlace]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "20%",
          left: "0",
          //transform: "translate(-50%, -50%)",
          bgcolor: "white",
          boxShadow: "24",
          borderRadius: 5,
          width: "100%",
          height: "60%",
          // overflowY: "scroll",
          overflow: "auto",
        }}
      >
        {renderInformationTab}
        {renderSignificanceTab}
        {renderLocationTab}
      </Box>
    </Modal>
  );
};

export default MobileHolyPlaceModal;
