import { Interface } from "readline";
import { Storage } from "../interfaces/constants";
import { getSessionStorage, setSessionStorage } from "../utils/sessionStorage";
import api from "./api";
import { useEffect } from "react";

interface APIResponse {
  success: boolean;
}

export interface Features {
  registration: {
    enabled: boolean;
    title: string;
    url: string;
  };
}

export interface VerifyOrder {
  id?: string;
  created_at?: string;
  updated_at?: string;
  uuid?: string;
  fname?: string;
  lname?: string;
  mobile?: string;
  pincode?: string;
  cart?: string;
  address_id?: string;
  status?: string;
  subtotal?: string;
  packaging_charges?: string;
  delivery_charges?: string;
  payment_id?: string;
}

export interface VerifyOrderResponse extends APIResponse {
  data: VerifyOrder;
}
export interface Order {
  uuid?: string;
  fname?: string;
  lname?: string;
  mobile?: string;
  cart?: string;
  address_id?: string;
  subtotal?: string;
  packaging_charges?: string;
  delivery_charges?: string;
  status?: string;
  pincode?: string;
  updated_at?: string;
  created_at?: string;
  id?: string;
}

export interface OrderResponse extends APIResponse {
  data?: { order?: Order; payment_link?: string; order_total?: string };
}
export interface Address {
  uuid?: string;
  street_address_1?: string;
  street_address_2?: string;
  city?: string;
  state?: string;
  pincode?: string;
  updated_at?: string;
  created_at?: string;
  id?: string;
}
export interface Puja {
  id?: string;
  created_at?: string;
  updated_at?: string;
  puja_name?: string;
  puja_date?: string;
  youtube_link?: string;
  image?: string;
  type?: "puja" | "workshop";
  cat_name?: string;
  deleted_at?: string;
  shipping_class?: string;
}

export interface Product {
  pid?: string;
  price?: "50";
  pname?: string;
  length?: string;
  breadth?: string;
  height?: string;
  weight?: string;
  available_qty?: string;
  sku?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  category_id?: string;
  category_name?: string;
  type?: "variable" | "single";
  image?: string;
  vid?: string;
  variation_name?: string;
  variation_value?: string;
  variations?: Product[];
  qty?: string;
}

interface PujaResponse extends APIResponse {
  data: Puja[];
}

interface ProductByCategoryResponse extends APIResponse {
  data: Product[];
}

interface ProductByIdResponse extends APIResponse {
  data: Product;
}

interface ProductResponse extends APIResponse {
  data: Product;
}

export const fetchFeatures = async (
  setData: (data: Features) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const res = (await api.get("/features")) as Features;
    setData(res);
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchPujas = async (
  setData: (data: Puja[]) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const res = (await api.get("/puja")) as PujaResponse;
    setData(res.data);
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchSMCategories = async (
  setData: (data: Puja[]) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const res = (await api.get("/products/category")) as PujaResponse;
    setData(res.data);
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchProductsByCategory = async (
  categoryName: string,
  setData: (data: Product[]) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);
  try {
    if (categoryName) {
      const res = (await api.get(
        `/products/byCategory/${categoryName}`
      )) as ProductByCategoryResponse;
      setData(res.data);
    }
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchProductsById = async (
  productId: string,
  setData: (data: Product) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);
  try {
    if (productId) {
      const res = (await api.get(
        `/products/${productId}`
      )) as ProductByIdResponse;
      setData(res.data);
    }
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const fetchProduct = async (
  pid: string,
  setData: (data: Product) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);

  try {
    if (pid) {
      const res = (await api.get(`/products/${pid}`)) as ProductResponse;
      setData(res.data);
    }
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const querySignIn = async (
  data: any,
  setData: (Puja: any) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const res: any = await api.post("/login", data);
    if (res?.success) {
      setSessionStorage(Storage.TOKEN, JSON.stringify(res.data?.token));
    }
    setData(res);
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const querySignUp = async (
  data: any,
  setData: (Puja: any) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const res = await api.post("/register", data);
    setData(res);
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const queryShippingCharges = async (
  cart: any[],
  pincode: string,
  setData: (data: string) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void,
  setErrorMsg: (error: string) => void
) => {
  setLoading(true);
  setError(null);
  setErrorMsg("");
  try {
    const res: any = await api.post("/calculateShipping", {
      cart: JSON.stringify(cart),
      pincode,
    });

    if (res?.success) {
      setData(res?.data);
      setError(null);
    } else {
      setError(true);

      if (JSON.parse(res?.data)?.error) {
        setErrorMsg(JSON.parse(res.data).msg);
      }
    }
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const createAddress = async (
  street_address_1: string,
  street_address_2: string,
  city: string,
  state: string,
  pincode: string,
  setData: (data: Address) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const token = getSessionStorage(Storage.TOKEN).replace(/['"]+/g, "");

    const res: any = await api.post("/address", {
      token,
      street_address_1,
      street_address_2,
      city,
      state,
      pincode,
    });

    if (res?.success) {
      setData(res?.data);
    }
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const createOrder = async (
  fname: string,
  lname: string,
  mobile: string,
  cart: string,
  address_id: string,
  pincode: string,
  setData: (data: {
    order?: Order;
    payment_link?: string;
    order_total?: string;
  }) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const token = getSessionStorage(Storage.TOKEN).replace(/['"]+/g, "");
    const res = (await api.post("/order", {
      token,
      fname,
      lname,
      mobile,
      cart,
      address_id,
      pincode,
    })) as OrderResponse;

    if (res?.success) {
      setData(res?.data);
    } else {
      setError(true);
    }
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const verifyOrder = async (
  orderId,
  orderToken,
  setData: (data: VerifyOrder) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const token = getSessionStorage(Storage.TOKEN);
    if (token) {
      let vtoken = token.replace(/['"]+/g, "");
      const res = (await api.get(
        `/order/verify?token=${vtoken}&orderId=${orderId}&orderToken=${orderToken}`
      )) as VerifyOrderResponse;

      setData(res.data);
    }
  } catch (error) {
    setData(null);
    setError(error);
  } finally {
    setLoading(false);
  }
};
