import React, { useCallback, useEffect, useState, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";

import CircularProgress from "@mui/material/CircularProgress";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Notification, {
  AlertColor,
} from "../../components/common/Notification/Notification";
import {
  Box,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Product,
  queryShippingCharges,
} from "../../services/let-data-services";
import { useLocation, useNavigate } from "react-router-dom";
import { Storage, Urls } from "../../interfaces/constants";
import {
  getObjectSessionStorage,
  setObjectSessionStorage,
} from "../../utils/sessionStorage";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PrimaryButton from "../../components/common/Button/PrimaryButton";

type Props = {};

function Cart({}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [cart, setCart] = useState<Product[]>([]);
  const [pincode, setPincode] = useState<string>("");
  const [charges, setCharges] = useState<string>("");
  const [loadingShippingCharges, setLoadingShippingCharges] =
    useState<boolean>(false);
  const [chargesLoading, setChargesLoading] = useState(false);
  const [chargesError, setChargesError] = useState(null);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [notification, setNotification] = useState<{
    title: string;
    severity: AlertColor;
  }>(null);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState<string>("");

  useEffect(() => {
    const loadCart = getObjectSessionStorage(Storage.CART);
    loadCart && setCart(loadCart);
  }, []);

  useEffect(() => {
    if (pincode.length === 6) {
      const _cart: any[] = cart.map((prod) => ({
        pid: prod.pid,
        vid: prod.vid,
        qty: prod.qty,
      }));
      if (_cart && pincode) {
        setLoadingShippingCharges(true);
        queryShippingCharges(
          _cart,
          pincode,
          setCharges,
          setChargesLoading,
          setChargesError,
          setErrorMsg
        );
      }
    }
  }, [pincode, cart]);

  useEffect(() => {
    if (chargesError === true) {
      setCharges("N/A");
    }
  }, [chargesError]);

  useEffect(() => {
    if (cart) {
      const _subTotal = cart.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.price) * Number(currentValue.qty),
        0
      );
      setSubTotal(_subTotal);
    }
  }, [cart]);

  useEffect(() => {
    if (cart && charges && subTotal) {
      const _total = subTotal + Number(charges) + subTotal * 0.2;
      setTotal(_total);
    }
  }, [charges, cart, subTotal]);

  const handleQuantityChange = (event: any, row: Product) => {
    const _quantity: string = event.target.value;
    const _cart = cart;
    const _filteredProd = _cart.find((prod) => prod.pid === row.pid);
    if (_filteredProd) {
      _filteredProd.qty = _quantity;
    }
    setCart(_cart);
  };

  const handleProceedToCheckout = useCallback(
    (
      cart: Product[],
      charges: number,
      subTotal: number,
      total: number,
      pincode: string,
      errorMsg: string
    ) => {
      if (cart && charges && subTotal && total && chargesError === null) {
        navigate(Urls.Checkout, {
          state: {
            cart,
            charges,
            subTotal,
            total,
            pincode,
            previousPath: location.pathname,
          },
        });
      } else {
        if (pincode.length == 6) {
          if (errorMsg !== "") {
            setNotification({ title: errorMsg, severity: "warning" });
            setOpen(true);
          } else {
            setNotification({
              title: "Sorry! we don't ship at this location",
              severity: "warning",
            });
            setOpen(true);
          }
        } else {
          setNotification({
            title: "Please enter pincode",
            severity: "warning",
          });
          setOpen(true);
        }
      }
    },
    []
  );

  const handleContinueShopping = () => {
    navigate(Urls.Shop);
  };

  const renderEmptyCart = useMemo(
    () => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ShoppingCartIcon sx={{ fontSize: "5rem", mt: "5%", mb: "1%" }} />
        <Typography align="center" variant="h4" sx={{ mt: "1rem", mb: "1rem" }}>
          Your cart is empty
        </Typography>
        <Typography align="center" variant="subtitle1">
          Looks like you have not added anything to your cart. <br />
          Please click on continue shopping to add products on your cart.
        </Typography>
        <Box sx={{ mt: "2rem", mb: "4rem" }}>
          <PrimaryButton onClick={handleContinueShopping}>
            Continue Shopping
          </PrimaryButton>
        </Box>
      </Box>
    ),
    []
  );

  return (
    <Box>
      {cart.length === 0 ? (
        renderEmptyCart
      ) : (
        <Box>
          {notification && (
            <Notification
              open={open}
              setOpen={setOpen}
              severity={notification.severity}
            >
              {notification.title}
            </Notification>
          )}
          {chargesLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={chargesLoading}
            >
              <CircularProgress />
            </Backdrop>
          )}
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              margin: "auto",
              my: theme.spacing(12),
            }}
          >
            <Table sx={{ minWidth: "100hv" }}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell align="center">Variation</TableCell>
                  <TableCell align="center">Price</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Subtotal</TableCell>
                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.map((row, index) => (
                  <TableRow key={row.pid}>
                    <TableCell>
                      <Box
                        component="img"
                        sx={
                          isMobile
                            ? { width: 1, height: "4rem", objectFit: "contain" }
                            : { width: 1, height: "4rem", objectFit: "contain" }
                        }
                        src={row.image[0]}
                        alt={row.pname}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.pname}
                    </TableCell>
                    <TableCell align="center">
                      {
                        row?.variations?.find((_pro) => _pro.vid === row.vid)
                          ?.variation_value
                      }
                    </TableCell>
                    <TableCell align="center">
                      &#8377;&nbsp;{row.price}
                    </TableCell>
                    <TableCell align="center">{row.qty}</TableCell>
                    <TableCell align="center">
                      &#8377;&nbsp;{Number(row.price) * Number(row.qty)}
                    </TableCell>
                    <TableCell align="center">
                      <DeleteOutlinedIcon
                        sx={{ color: "red", cursor: "pointer" }}
                        onClick={() => {
                          const _cart = cart.filter(
                            (item) => item != cart[index]
                          );
                          setCart(_cart);
                          setObjectSessionStorage(Storage.CART, _cart);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            sx={{
              margin: "auto",
              width: isMobile ? "80%" : "60%",
              mb: theme.spacing(12),
            }}
          >
            <Table sx={{ minWidth: "100hv" }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    Cart total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={0}>
                  <TableCell>Subtotal</TableCell>
                  <TableCell>
                    &#8377;&nbsp;
                    {subTotal ? subTotal : 0}
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell>Shipping</TableCell>
                  <TableCell>
                    <Box sx={{ mb: theme.spacing(4) }}>
                      <input
                        placeholder="Enter pincode for shipping charges"
                        style={{ width: "10rem", height: "1.5rem" }}
                        value={pincode}
                        onChange={(event) => {
                          if (event.target.value.length < 7)
                            setPincode(event.target.value);
                        }}
                      />
                    </Box>
                    <Box>&#8377;&nbsp;{charges ? charges : 0}</Box>
                  </TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell>Packaging charges</TableCell>
                  <TableCell>
                    &#8377;&nbsp;
                    {subTotal ? subTotal * 0.2 : 0}
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell>Total</TableCell>
                  <TableCell>
                    &#8377;&nbsp;
                    {total ? total : 0}
                  </TableCell>
                </TableRow>
                <TableRow
                  key={3}
                  sx={{
                    background: theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleProceedToCheckout(
                      cart,
                      Number(charges),
                      subTotal,
                      total,
                      pincode,
                      errorMsg
                    )
                  }
                >
                  <TableCell colSpan={2} align="center">
                    <strong>Proceed to checkout</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}

export default Cart;
