import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  createTheme,
  Tabs,
  Tab,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import WeeklyCenter from "./WeeklyCenter";
import UpcomingPuja from "./UpcomingPuja";
import PastPuja from "./PastPuja";
import Workshops from "./Workshops";
import { appTheme } from "../../styles/theme";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../components/common/Accordian/Accordian";
import { useLocation } from "react-router-dom";
import { fetchPujas, Puja } from "../../services/let-data-services";

const image: { label: string; imgPath: string } = {
  label: "Image1",
  imgPath: "images/Banner-1.png",
};

const useStyles = makeStyles({
  box: {
    width: "80%",
    margin: "auto",
    background: "#8E0024",
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
  },
  text: {
    color: "#FFCB78",
    textTransform: "none",
  },
  content: {
    margin: "auto",
    width: "80%",
  },
});
const Events = () => {
  const { t } = useTranslation();
  const theme = createTheme();
  const classes = useStyles(theme);
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [pujas, setPujas] = useState<Puja[]>([]);
  const [pujasLoading, setPujasLoading] = useState(false);
  const [pujasError, setPujasError] = useState(null);

  useEffect(() => {
    fetchPujas(setPujas, setPujasLoading, setPujasError);
  }, []);

  useEffect(() => {
    if (location && location.state && location.state.activeIndex) {
      setTabIndex(location.state.activeIndex);
    } else {
      setTabIndex(0);
    }
    if (location && location.state && location.state.activeTab) {
      setExpanded(location.state.activeTab);
    } else {
      setExpanded("weeklyCenter");
    }
  }, [location]);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: appTheme.spacing(2),
        }}
      >
        <Box
          component="img"
          sx={{ width: 1 }}
          src={require("../../assets/holy_place_hero_card.png")}
          alt={"holy_place_hero_card"}
        />
        {!isMobile && (
          <Box>
            <Box sx={{ pb: `${appTheme.spacing(1)}` }} className={classes.box}>
              <Tabs value={tabIndex} onChange={handleTabChange} centered>
                <Tab className={classes.text} label="Weekly Center" />
                <Tab className={classes.text} label="Upcoming Puja" />
                <Tab className={classes.text} label="Past Puja" />
                <Tab className={classes.text} label="Workshops" />
              </Tabs>
            </Box>
            <Box className={classes.content}>
              <Box sx={{ padding: `${appTheme.spacing(2)}` }}>
                {tabIndex === 0 && <WeeklyCenter />}
                {tabIndex === 1 && (
                  <UpcomingPuja
                    pujas={pujas.filter(
                      (puja) =>
                        new Date() < new Date(puja.puja_date) &&
                        puja.type === "puja"
                    )}
                  />
                )}
                {tabIndex === 2 && (
                  <PastPuja
                    pujas={pujas.filter(
                      (puja) =>
                        new Date() > new Date(puja.puja_date) &&
                        puja.type === "puja"
                    )}
                  />
                )}
                {tabIndex === 3 && (
                  <Workshops
                    workshops={pujas.filter((puja) => puja.type === "workshop")}
                  />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {isMobile && (
        <Box
          sx={{ mt: `${appTheme.spacing(10)}`, mb: `${appTheme.spacing(10)}` }}
        >
          <Accordion
            sx={{ background: appTheme.palette.secondary.main }}
            expanded={expanded === "weeklyCenter"}
            onChange={handleChange("weeklyCenter")}
          >
            <AccordionSummary id={"weeklyCenter"}>
              <Typography
                variant="subtitle2"
                sx={{ color: appTheme.palette.primary.main }}
              >
                {"Weekly Center"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white" }}>
              <Box>
                <Box>
                  <WeeklyCenter />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "upcomingPuja"}
            onChange={handleChange("upcomingPuja")}
            sx={{ background: "#8E0024" }}
          >
            <AccordionSummary id={"upcomingPuja"}>
              <Typography
                variant="subtitle2"
                sx={{ color: appTheme.palette.primary.main }}
              >
                {"Upcoming Puja"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white" }}>
              <UpcomingPuja
                pujas={pujas.filter(
                  (puja) =>
                    new Date() < new Date(puja.puja_date) &&
                    puja.type === "puja"
                )}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "pastPuja"}
            onChange={handleChange("pastPuja")}
            sx={{ background: appTheme.palette.secondary.main }}
          >
            <AccordionSummary id={"pastPuja"}>
              <Typography
                variant="subtitle2"
                sx={{ color: appTheme.palette.primary.main }}
              >
                {"Past Puja"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white" }}>
              <PastPuja
                pujas={pujas.filter(
                  (puja) =>
                    new Date() > new Date(puja.puja_date) &&
                    puja.type === "puja"
                )}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "workshop"}
            onChange={handleChange("workshop")}
            sx={{ background: appTheme.palette.secondary.main }}
          >
            <AccordionSummary id={"workshop"}>
              <Typography
                variant="subtitle2"
                sx={{ color: appTheme.palette.primary.main }}
              >
                {"Workshops"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white" }}>
              <Workshops
                workshops={pujas.filter((puja) => puja.type === "workshop")}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </div>
  );
};

export default Events;
