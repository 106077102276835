import React from "react";
import { Box, Typography } from "@mui/material";
import { appTheme } from "../../styles/theme";
import { Puja } from "../../services/let-data-services";

type UpcomingPujaProps = {
  pujas: Puja[];
};

const UpcomingPuja = ({ pujas }: UpcomingPujaProps) => {
  return (
    <>
      {pujas.map((puja) => (
        <Box>
          <Typography variant="h3" align="center">
            {puja.puja_name}
          </Typography>
          <Box
            component="img"
            sx={{ width: 1, mt: `${appTheme.spacing(5)}` }}
            src={puja.image}
            alt={puja.puja_name}
          />
        </Box>
      ))}
    </>
  );
};

export default UpcomingPuja;
