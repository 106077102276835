import React, { useState } from "react";
import { Box, Modal, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { appTheme } from "../../styles/theme";
import InformationTab from "./InformationTab";
import SignificanceTab from "./SignificanceTab";
import LocationTab from "./LocationTab";

const useStyles = makeStyles({
  holyPlaceModal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    boxShadow: "24",
    padding: appTheme.spacing(4),
    borderRadius: 5,
    width: "80%",
    height: "80%",
    overflow: "auto",
  },
  tabPanel: {
    height: "80%",
    padding: appTheme.spacing(4),
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const HolyPlaceModal = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { open, setOpen, selectedHolyPlace } = props;
  const modalTabs: string[] = [
    "holyPlaces.information",
    "holyPlaces.significance",
    "holyPlaces.location",
  ];

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box className={classes.holyPlaceModal}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {modalTabs.map((tab: string, index: number) => (
              <Tab key={index} label={t(tab)} />
            ))}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <InformationTab selectedHolyPlace={selectedHolyPlace} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SignificanceTab selectedHolyPlace={selectedHolyPlace} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <LocationTab selectedHolyPlace={selectedHolyPlace} />
        </TabPanel>
      </Box>
    </Modal>
  );
};

export default HolyPlaceModal;
