import React, {useState} from "react";
import { Box, Button, Container, createTheme, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import Header from "../../components/header/Header";
import ImageCarousel from "../../components/ImageCarousel";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
});
const image1: { label: string; imgPath: string } = {
    label: "Image1",
    imgPath: "images/Vision_img1.jpg",
  };
  const image2: { label: string; imgPath: string } = {
    label: "Image1",
    imgPath: "images/Vision_img2.jpg",
  };
  const breakImage: { label: string; imgPath: string } = {
    label: "Image3",
    imgPath: "images/XMLID_1_.png",
  };
  const ShreeMatajiImage: { label: string; imgPath: string } = {
    label: "Image3",
    imgPath: "images/Shree-Mataji-photo-about.png",
  };
const Vision = () => {
    const theme = createTheme();
    const classes = useStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation();
    return (
        <>
        <Box sx={{ mx: theme.spacing(5), mt:theme.spacing(3), fontFamily:'Work Sans', textAlign: 'center'  }}>
            
          <Typography variant="h3" >
            {t("header.vision")}
          </Typography>
       
        </Box> 
        <Grid item container direction="row">
            <Grid item md={6}>
            <Box
        component="img"
        
        sx={{ width: 1, mt: theme.spacing(2),  }}
        src={image1.imgPath}
        alt={image1.label}
      />
            </Grid>
            <Grid item md={6} sx={!isMobile && {paddingLeft: `${theme.spacing(2)}`}}>
            <br/>
            <Typography variant="body2">
            {t("vision.visionLine1")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine3")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine4")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine5")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine6")}
          </Typography>
            </Grid>
        </Grid>
      
    
        <Typography variant="body2">
            {t("vision.visionLine7")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine8")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine9")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine10")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine12")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine13")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine15")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("vision.visionLine16")}
          </Typography>
          <Box
        component="img"
        
        sx={{ width: 0.095, margin:'auto', mt: theme.spacing(2), display:'block' }}
        src={breakImage.imgPath}
        alt={breakImage.label}
      />
          <Box sx={{ mt:theme.spacing(3), fontFamily:'Work Sans',  }}>
            
          <Typography variant="h4" >
            {t("header.herSharedVision")}
          </Typography>
       
        </Box> 
        <Grid item container direction="row">
        <Grid item md={8} sx={{paddingRight: `${theme.spacing(2)}`}}>
            <br/>
            <Typography variant="body2">
            {t("herSharedVision.herSharedVision1")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("herSharedVision.herSharedVision2")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("herSharedVision.herSharedVision3")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("herSharedVision.herSharedVision4")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("herSharedVision.herSharedVision5")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("herSharedVision.herSharedVision6")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("herSharedVision.herSharedVision7")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("herSharedVision.herSharedVision8")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("herSharedVision.herSharedVision9")}
          </Typography>
            </Grid>
            <Grid item md={4}>
            <Box
        component="img"
        
        sx={{ width: 1, mt: theme.spacing(2),  }}
        src={image2.imgPath}
        alt={image2.label}
      />
            </Grid>
            
        </Grid>
      
          <Typography variant="body2">
            {t("herSharedVision.herSharedVision10")}
          </Typography>
          <Box
        component="img"
        
        sx={{ width: 0.15, margin:'auto', mt: theme.spacing(2),display:'block'}}
        src={breakImage.imgPath}
        alt={breakImage.label}
      />
        </>
    );
};

export default Vision;
