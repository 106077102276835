import { Box, Typography, createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const TermsAndConditions = () => {
  const theme = createTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //textAlign: "left",
        //mx: theme.spacing(4),
        //mt: theme.spacing(4),
        mb: theme.spacing(2),
        mt: theme.spacing(1),
        pl: "2%",
        pb: "2%",
      }}
    >
      <Typography variant="h3">Terms and Conditions</Typography>
      <br />
      <Typography variant="h4">
        Customer Registration and Privacy Policy
      </Typography>
      <br />
      <Typography>
        The site provides for registration process to serve its Customer better
        and may now or in the future collect the following personally
        identifiable information to serve you better: a) Name including first
        and last name; b) email address; c) mobile phone numbers and contact
        details; d) Postal Pin code; e) Personal demographic profile like your
        age, gender, occupation, location, education, address etc., By
        completing the registration or placing an order, the Customer agrees to
        receive promotional and transactional communication, text messages,
        mobile notifications, phone calls and newsletters. The Customer can opt
        out by contacting the customer service at nirmalsahityasales@gmail.com.
        We will never sell or provide your information to third party companies
        or outside agencies for commercial purposes. However, the Site may
        contain links to other web sites that we do not manage directly such as
        Tracking Services, Google Analytics used to understand and optimize
        users behavior. Nirmal Sahitya LLP shall not be responsible for the
        privacy policies of such external web sites.
      </Typography>
      <br />
      <Typography variant="h4">Who Can Sign Up</Typography>
      <br />
      <Typography>
        Any person can sign up for services provided he or she is competent to
        enter into a contract. Section 11 of the Indian Contract Act, 1872
        specifies that every person is competent to contract provided he or she
        has attained the age of 18 years, is of sound mind and not disqualified
        from contracting by any other law to which he or she is subject to.
      </Typography>
      <br />
      <Typography variant="h4">Terms of Site access</Typography>
      <br />
      <Typography>
        Nirmal Sahitya LLP grants customers limited access rights for personal
        use on this site and not to download (other than page caching) or edit
        any portion of it. The access to this site is for personal use of the
        Customer and not for any commercial use or to access its contents to
        collect and use of product listings, descriptions, or prices; any
        derivative use of this site or any use of data mining, robots, or
        similar data gathering and extraction tools. No portions of this site
        may be reproduced, duplicated, copied, sold, resold or otherwise
        exploited for any commercial purpose without express written consent of
        Nirmal Sahitya LLP. Further no Customer is authorized to frame or
        utilize framing techniques to enclose any trademark, logo, or other
        proprietary information (including images, text, page layout, or form)
        on the Site without express written consent. Any unauthorized use
        automatically terminates the permission granted by Nirmal Sahitya LLP.
      </Typography>
      <br />
      <Typography variant="h4">Pricing</Typography>
      <br />
      <Typography>
        The prices of all products mentioned at the time of ordering will be
        charged on the date of the delivery of products. In case prices are
        higher or lower on the date of delivery, additional charges will be
        collected or refunded as the case may be at the time of the delivery of
        the order.
      </Typography>
      <br />
      <Typography variant="h4">Order Cancellation</Typography>
      <br />
      <Typography>
        A Customer can opt to cancel order within 1 hour for the order placed by
        calling at 9168763232 and receive refund of advance paid if any with 2%
        deduction. Nirmal Sahitya LLP reserves the right to cancel any order if
        it suspects any fraudulent transaction by a Customer or breaches the
        terms & conditions of using the website
      </Typography>
      <br />
      <Typography variant="h4">Refunds and Cancellation</Typography>
      <br />
      <Typography>
        Nirmal Sahitya LLP believes in Centum Customer satisfaction and “no
        questions asked return and refund policy” for issues relating to
        quality. Refunds and Cancellation can only be requested within an hour
        of lacing the order. After the request 2% of the amount will be deducted
        from the payment and rest 98% of the payment will processed in your bank
        account within 48 hours and will be credited to the customer account
        within 5-7 working days depending on the issuing bank. The return policy
        applied from 7 days if the Client is unsatisfied or having any problem
        in the Product so it will be replaced as soon as possible after
        receiving back the parcle. Freight Charges for the return will be bared
        by Nirmal Sahitya LLP.
      </Typography>
      <br />
      <Typography variant="h4">Customer Acceptance</Typography>
      <br />
      <Typography>
        I hereby agree upon: To pay extra cost claimed by Nirmal Sahitya LLP in
        the case of redelivery due to wrong name or address or any other wrong
        information provided while booking order. To use
        www.thelifeeternaltrustpune.org/sahaja-material site to transact for
        lawful purposes in compliance with all applicable laws and regulation.
        To provide authentic and true information in all instances and authorize
        Nirmal Sahitya LLP to validate the information provided at any point to
        time and reject registration if any details are found to be untrue
        wholly or partly. To access the services made available on
        www.thelifeeternaltrustpune.org/sahaja-material and to transact business
        at my own risk after using best and prudent judgement as a well-informed
        Customer. That the delivery address provided is correct and proper in
        all respects. That the product descriptions have been carefully checked
        before placing the order and agree to be bound by the terms and
        conditions of sale.
      </Typography>
      <br />
      <Typography variant="h4">Obligations of Visitor / Customer</Typography>
      <br />
      <Typography>
        <ul>
          <li>
            Disseminating any objectionable material, harassing, libelous,
            abusive, threatening, harmful, vulgar, obscene, or any unlawful
            activity.
          </li>
          <li>
            To transmit material that constitutes a criminal offence or results
            in civil liability or otherwise breaches any relevant laws,
            regulations or code of practice.
          </li>
          <li>
            To gain unauthorized access to other computer network systems.
          </li>
          <li>
            Interfere with any other person’s right to use or enjoyment of the
            Site.
          </li>
          <li>Breach applicable laws.</li>
          <li>
            Interfere or disrupt networks or web sites connected to the Site.
          </li>
          <li>
            Make, transmit or store electronic copies of materials protected by
            copyright without the permission of the owner.
          </li>
          <li>
            To post customer review feedback in violation of this policy or
            right of &nbsp;any third party, including copyright, trademark,
            privacy or other personal or proprietary right(s), and &nbsp;cause
            injury to any person or entity.
          </li>
          <li>
            To post comments containing libelous or otherwise unlawful,
            threatening, abusive or obscene material, or contain software
            viruses, political campaigning, commercial solicitation, chain
            letters, mass mailings or any form of “spam”.
          </li>
          <li>
            Not to use a false email address, impersonate any person or entity,
            or otherwise mislead as to the origin of any Customer feedback
            submitted on{" "}
            <a href="http://www.nirmalsahitya.org">
              <u>www.</u>
            </a>
            thelifeeternaltrustpune.org/nirmal-sahitya.
          </li>
          <li>
            To be solely responsible for the content of any Customer Feedback
            made and agree upon to indemnify Nirmal Sahitya LLP for all claims
            resulting from such Customer Feedback submitted.
          </li>
        </ul>
      </Typography>
      <br />
      <Typography variant="h4">Pictures & Colors </Typography>
      <br />
      <Typography>
        Nirmal Sahitya LLP has have made every effort to display the products
        and its colors on the Website as accurately as possible. Nirmal Sahitya
        LLP does not guarantee nor take responsibility for variations in
        pictorial representations of the products.
      </Typography>
      <br />
      <Typography variant="h4">
        Right to Modify Terms & Conditions of Service
      </Typography>
      <br />
      <Typography>
        Nirmal Sahitya LLP reserves unconditional right to modify terms and
        conditions of transacting business on
        www.thelifeeternaltrustpune.org/nirmal-sahitya without any prior
        notification and consent of customers. When a registered customer
        accesses www.thelifeeternaltrustpune.org/nirmal-sahitya he or she is
        deemed to have accepted the latest version of the Terms & Conditions on
        the Site.
      </Typography>
      <br />
      <Typography variant="h4">Customer Reviews & Feedback</Typography>
      <br />
      <Typography>
        Customer reviews, comments, feedback, postcards, suggestions, ideas, and
        other submissions submitted or offered on
        www.thelifeeternaltrustpune.org/nirmal-sahitya in connection with use of
        this Site (collectively, the “Feedback”) shall be and remain the
        property of Nirmal Sahitya LLP. The Customer review and feedback made on
        the site shall constitute an assignment to Nirmal Sahitya LLP of all
        worldwide rights, titles and interests in all copyrights and other
        intellectual properties in the Feedback and Nirmal Sahitya LLP shall
        have exclusive rights, titles and interests therein without being
        limited in any way for its use, commercial or otherwise, of the
        Feedback. Further Nirmal Sahitya LLP will be entitled to use, reproduce,
        disclose, modify, adapt, create derivative works from, publish, display
        and distribute any Feedback submitted for any purpose whatsoever,
        without restriction and without compensating Customer in any way. Nirmal
        Sahitya LLP shall be under no obligation (1) to maintain any Comments in
        confidence; (2) to pay you any compensation for any Comments; or (3) to
        respond to any Comments. Nirmal Sahitya LLP reserves the right (but not
        the obligation) to monitor and edit or remove any Comments submitted to
        the Site. Nirmal Sahitya LLP does not take any responsibility or assume
        liability for any Feedback submitted by Customers or any third party.
      </Typography>
      <br />
      <Typography variant="h4">Copyright & Trademark Rights</Typography>
      <br />
      <Typography>
        The Customer acknowledges that access to this Website does not confer
        and shall not be considered as conferring upon anyone any license under
        any of Nirmal Sahitya LLP or any third party’s intellectual property
        rights. Nirmal Sahitya LLP and its suppliers and licensors expressly
        reserve all intellectual property rights in all text, programs,
        products, processes, technology, content and other materials, which
        appear on www.thelifeeternaltrustpune.org/sahaja-material.
      </Typography>
      <br />
      <Typography variant="h4">
        All rights, including copyright, on this website is owned by Nirmal
        Sahitya LLP.
      </Typography>
      <br />
      <Typography>
        Any use of this website or its contents, including copying or storing it
        either in whole or part, is prohibited without the permission of Nirmal
        Sahitya LLP. The names and logos and all related product and service
        names, design marks and slogans are the trademarks or service marks of
        Nirmal Sahitya LLP, its affiliates, its partners or its suppliers /
        respective companies. References on
        www.thelifeeternaltrustpune.org/sahaja-material to any names, marks,
        products or services of third parties or hypertext links to third party
        sites or information provided is solely for customer convenience and
        does not in any way constitute or imply Nirmal Sahitya LLP endorsement,
        sponsorship or recommendation of the third party, information, product
        or service. Nirmal Sahitya LLP is not responsible for content of any
        third party sites and does not make any representations regarding the
        content or accuracy of material on such sites. Customers deciding to
        link to any such third party websites, are doing entirely at their own
        risk. All materials, including images, text, illustrations, designs,
        icons, photographs, programs, music clips or downloads, video clips and
        written and other materials hosted on
        www.thelifeeternaltrustpune.org/sahaja-material (collectively, the
        “Promotional Material”) are intended solely for customer convenience.
        The Contents and software in
        www.thelifeeternaltrustpune.org/sahaja-material to be used only as a
        shopping resource. Any other use, including the reproduction,
        modification, distribution, transmission, republication, display, of the
        Contents on this Website is strictly prohibited.
      </Typography>
      <br />
      <Typography variant="h4">Objectionable Material</Typography>
      <br />
      <Typography>
        While all IT security measures are taken to ensure wholesome and
        socially acceptable content is available on
        www.thelifeeternaltrustpune.org/nirmal-sahitya , notwithstanding the
        best efforts the customer understands and accepts the risk that while
        using this Site or any services provided on the
        www.thelifeeternaltrustpune.org/nirmal-sahitya, one may encounter
        Content that may be deemed by some to be offensive, indecent, or
        objectionable, which Content may or may not be identified as such.
        Nirmal Sahitya LLP and its affiliates shall have no liability to
        Customer for Content that may be deemed offensive, indecent, or
        objectionable to you.
      </Typography>
      <br />
      <Typography variant="h4">Indemnity</Typography>
      <br />
      <Typography>
        As a Customer, I hereby agree upon to defend, indemnify and hold
        harmless Nirmal Sahitya LLP, its employees, partners, directors,
        officers, agents and their successors and assigns from and against any
        and all claims, liabilities, damages, losses, costs and expenses,
        including attorney’s fees, caused by or arising out of claims based upon
        my actions or inactions, which may result in any loss or liability to
        Nirmal Sahitya LLP or any third party including but not limited to
        breach of any warranties, representations or undertakings or in relation
        to the non-fulfilment of any obligations under this User Agreement or
        arising out of violation of any applicable laws, regulations including
        but not limited to Intellectual Property Rights, payment of statutory
        dues and taxes, claim of libel, defamation, violation of rights of
        privacy or publicity, loss of service to other subscribers and
        infringement of intellectual property or other rights. This clause shall
        survive the expiry or termination of this User Agreement. We may
        terminate this User Agreement at any time, without notice or liability
        to Nirmal Sahitya LLP.
      </Typography>
      <br />
    </Box>
  );
};

export default TermsAndConditions;
