import React from "react";
import "./styles.css";
import YoutubeEmbed from "./YoutubeEmbed";
import { Box, Typography } from "@mui/material";
import { Puja } from "../../services/let-data-services";

type PastPujaProps = {
  pujas: Puja[];
};

const PastPuja = ({ pujas }: PastPujaProps) => {
  return (
    <>
      {pujas.map((puja) => (
        <Box>
          <Typography variant="h3" align="center">
            {puja.puja_name}
          </Typography>
          <Box sx={{ mt: "2%" }}>
            <YoutubeEmbed embedId={puja.youtube_link} />
            <br />
          </Box>
        </Box>
      ))}
    </>
  );
};

export default PastPuja;
