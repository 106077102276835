import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Tabs,
  Tab,
  Box,
  Typography,
  createTheme,
  Grid,
  Button,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Link,
} from "@mui/material";
import PrimaryButton from "../components/common/Button/PrimaryButton";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import InputUnstyled from "@mui/base/InputUnstyled";
import { useTranslation } from "react-i18next";
import { json } from "stream/consumers";
import { appTheme } from "../styles/theme";

const useStyles = makeStyles((theme) => ({
  box: {
    width: "80%",
    margin: "auto",
    background: "#8E0024",
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
  },
  boxMobile: {
    width: "90%",
    margin: "auto",
    background: "#8E0024",
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
  },
  text: {
    color: "#FFCB78",
    textTransform: "none",
  },
  button: {
    borderRadius: 5,
    boxShadow: "none",
    margin: appTheme.spacing(4),
    px: appTheme.spacing(6),
    "&:hover": { backgroundColor: "primary.main" },
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flexContainerMobile: {
    display: "flex",
    flexDirection: "column",
  },
  flexItem: {
    flex: "50%",
    padding: "1%",
  },
  input: {},
  form: {
    width: "80%",
    margin: "auto",
  },
  formMobile: {
    width: "90%",
    margin: "auto",
  },
  link: {
    margin: "auto",
    textAlign: "center",
    paddingBottom: 10,
  },
}));
const image: { label: string; imgPath: string } = {
  label: "Image1",
  imgPath: "images/Banner-1.png",
};
const FreeClasses = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [err, setErr] = useState(false);
  const [errText, setErrText] = useState("");
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [skipCount, setSkipCount] = useState(true);

  const [flag, setFlag] = useState(false);

  const validEmail = new RegExp(
    "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
  );
  const validMobile = new RegExp("^[0-9]{10}$");

  const handleSubmit = () => {
    if (name === "") {
      setNameErr(true);
      setFlag(false);
    } else {
      setNameErr(false);
    }

    if (!validEmail.test(email)) {
      setEmailErr(true);
      setFlag(false);
    } else {
      setEmailErr(false);
    }

    if (!validMobile.test(mobile)) {
      setMobileErr(true);
      setFlag(false);
    } else {
      setMobileErr(false);
    }

    if (city === "") {
      setCityErr(true);
      setFlag(false);
    } else {
      setCityErr(false);
    }
  };

  useEffect(() => {
    if (flag) {
      Axios.post("http://127.0.0.1:8000/api/free_classes/", {
        name: name,
        email: email,
        mobile: mobile,
        city: city,
      }).then((res) => {
        if (!res.data.success) {
          setErr(true);
          setErrText(JSON.stringify(res.data.error));
        } else {
          setErr(false);
          setSuccess(true);
          setName("");
          setEmail("");
          setMobile("");
          setCity("");
          setNameErr(false);
          setMobileErr(false);
          setEmailErr(false);
          setCityErr(false);
        }
      });
    }
  }, [flag]);

  useEffect(() => {
    if (skipCount) setSkipCount(false);
    else handleSubmit();
  }, [submitted]);

  const onSubmit = (e) => {
    setFlag(true);
    setSubmitted(!submitted);
  };

  const { t } = useTranslation();
  const theme = createTheme();
  const classes = useStyles(theme);

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleClose = () => {
    setSuccess(false);
  };

  const centersAcrossIndia = () => {
    window.open(
      "https://sycenters.org/centers/India?q=&dow=0&ctime=0",
      "_blank"
    );
  };

  const centersAcrossWorld = () => {
    window.open("https://sycenters.org/centers", "_blank");
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          mb: appTheme.spacing(2),
        }}
      >
        <Box
          component="img"
          sx={{ width: 1 }}
          src={require("../assets/holy_place_hero_card.png")}
          alt={"holy_place_hero_card"}
        />
        <Box
          sx={{ pb: 1 }}
          className={isMobile ? classes.boxMobile : classes.box}
        >
          {!isMobile && (
            <Tabs value={tabIndex} onChange={handleTabChange} centered>
              <Tab
                className={classes.text}
                label={t("freeClasses.registerForFreeClasses")}
              />
              <Tab
                className={classes.text}
                label={t("freeClasses.centersAcrossIndia")}
                onClick={centersAcrossIndia}
              />
              <Tab
                className={classes.text}
                label={t("freeClasses.centersAcrossWorld")}
                onClick={centersAcrossWorld}
              />
            </Tabs>
          )}
          {isMobile && (
            <Tabs value={tabIndex} onChange={handleTabChange} centered>
              <Tab
                className={classes.text}
                label={t("freeClasses.registerForFreeClasses")}
              />
            </Tabs>
          )}
        </Box>
      </Box>
      <Box
        component="form"
        sx={
          isMobile
            ? {}
            : { px: `${appTheme.spacing(20)}`, my: `${appTheme.spacing(10)}` }
        }
        noValidate
        autoComplete="off"
      >
        <form className={isMobile ? classes.formMobile : classes.form}>
          <Box
            className={
              isMobile ? classes.flexContainerMobile : classes.flexContainer
            }
          >
            <Box className={classes.flexItem}>
              <Typography>{t("freeClasses.name")}</Typography>
              <TextField
                required
                error={nameErr}
                sx={{ width: "100%" }}
                id="name"
                className={classes.input}
                helperText={nameErr && t("freeClasses.nameErr")}
                onChange={(e) => setName(e.target.value)}
                value={name}
              ></TextField>
            </Box>
            <Box className={classes.flexItem}>
              <Typography>{t("freeClasses.email")}</Typography>
              <TextField
                error={emailErr}
                sx={{ width: "100%" }}
                id="email"
                className={classes.input}
                helperText={emailErr && t("freeClasses.emailErr")}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
              ></TextField>
            </Box>
          </Box>
          <Box
            className={
              isMobile ? classes.flexContainerMobile : classes.flexContainer
            }
          >
            <Box className={classes.flexItem}>
              <Typography>{t("freeClasses.mobile")}</Typography>
              <TextField
                error={mobileErr}
                sx={{ width: "100%" }}
                id="mobile"
                className={classes.input}
                helperText={mobileErr && t("freeClasses.mobileErr")}
                onChange={(e) => setMobile(e.target.value)}
                type="number"
                value={mobile}
              ></TextField>
            </Box>
            <Box className={classes.flexItem}>
              <Typography>{t("freeClasses.city")}</Typography>
              <TextField
                error={cityErr}
                sx={{ width: "100%" }}
                id="city"
                className={classes.input}
                helperText={cityErr && t("freeClasses.cityErr")}
                onChange={(e) => setCity(e.target.value)}
                value={city}
              ></TextField>
            </Box>
          </Box>

          <Box textAlign="center">
            <PrimaryButton
              variant="contained"
              disableRipple
              className={classes.button}
              onClick={onSubmit}
            >
              {t("freeClasses.submit")}
            </PrimaryButton>
          </Box>
          {isMobile && (
            <Box>
              <Box className={classes.link}>
                <Link
                  href="https://sycenters.org/centers/India?q=&dow=0&ctime=0"
                  target="_blank"
                  underline="always"
                >
                  {t("freeClasses.centersAcrossIndia")}
                </Link>
              </Box>
              <Box className={classes.link}>
                <Link
                  href="https://sycenters.org/centers/"
                  target="_blank"
                  underline="always"
                >
                  {t("freeClasses.centersAcrossWorld")}
                </Link>
              </Box>
            </Box>
          )}
        </form>
      </Box>
      <Dialog
        open={success}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Thank you submitting the details"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("freeClasses.successfulSubmissionMsg")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t("freeClasses.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FreeClasses;
