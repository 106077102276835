import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { appTheme } from "../../styles/theme";
import PrimaryButton from "../../components/common/Button/PrimaryButton";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  cardContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "18rem",
    borderRadius: 20,
    margin: `${appTheme.spacing(2)} ${appTheme.spacing(2)}`,
  },
  imgContainer: {
    width: "18rem",
  },
  cardContent: {
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  learnMoreButton: {
    margin: appTheme.spacing(4),
  },
});

const HolyPlaceCard = (props) => {
  const { t } = useTranslation();
  const clx = useStyles();
  const { holyPlace, setOpen, setSelectedHolyPlace } = props;
  const isMobile = appTheme.breakpoints.down("md");
  return (
    <Box className={clx.cardContainer}>
      <Box
        className={clx.imgContainer}
        component="img"
        src={holyPlace.img}
        alt={"holy_place_1"}
      />
      <Box className={clx.cardContent}>
        <Typography variant={isMobile ? "subtitle2" : "h6"} color={"white"}>
          {holyPlace.name1}
        </Typography>
        <Typography variant={isMobile ? "subtitle2" : "h6"} color={"white"}>
          {holyPlace.name2}
        </Typography>
        <Box>
          <PrimaryButton
            className={clx.learnMoreButton}
            onClick={() => {
              setOpen(true);
              setSelectedHolyPlace(holyPlace);
            }}
          >
            {t("holyPlaces.learnMore")}
          </PrimaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export default HolyPlaceCard;
