import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Urls } from "../interfaces/constants";
import { getSessionStorage } from "../utils/sessionStorage";

type Props = {
  children: any;
  accessible: boolean;
};

function RequireAuth({ children, accessible = true }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const token = getSessionStorage("token");

  return token ? (
    location.pathname === Urls.Checkout &&
    location.state?.previousPath !== Urls.Cart ? (
      <Navigate
        to={Urls.Cart}
        replace
        state={{ path: location.state?.originalPath, fromRequireAuth: true }}
      />
    ) : (
      children
    )
  ) : (
    <Navigate
      to={Urls.SMHomepage}
      replace
      state={
        accessible
          ? { path: location.state?.originalPath, fromRequireAuth: true }
          : {}
      }
    />
  );
}

export default RequireAuth;
