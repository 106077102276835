import React from "react";
import { Box, createTheme, Typography, useMediaQuery } from "@mui/material";
import PrimaryButton from "../../components/common/Button/PrimaryButton";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { appTheme } from "../../styles/theme";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles({
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: `${appTheme.spacing(8)} ${appTheme.spacing(8)}`,
  },
  flexContainerMobile: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  flexItem: {
    padding: "1%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    padding: `${appTheme.spacing(4)} ${appTheme.spacing(
      4
    )} 0 ${appTheme.spacing(4)}`,
    marginRight: appTheme.spacing(4),
    marginBottom: appTheme.spacing(4),
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "40%",
    padding: `${appTheme.spacing(2)} ${appTheme.spacing(
      2
    )} 0 ${appTheme.spacing(2)}`,
    marginRight: appTheme.spacing(2),
    marginBottom: appTheme.spacing(2),
  },
  readMoreButton: {
    margin: appTheme.spacing(4),
  },
});

const Workshops = (props) => {
  const { t } = useTranslation();
  const theme = createTheme();
  const clx = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { workshops } = props;

  return (
    <Box className={isMobile ? clx.flexContainerMobile : clx.flexContainer}>
      {workshops?.map((workshop) => (
        <Paper className={isMobile ? clx.cardMobile : clx.card} elevation={5}>
          <Box
            component="img"
            sx={{ width: 1, height: 1 }}
            src={workshop.image}
            alt={workshop.puja_name}
          />
          <Typography
            variant="h5"
            fontWeight="bold"
            align="center"
            sx={{ mb: "2%" }}
          >
            {workshop.puja_name}
          </Typography>
        </Paper>
      ))}
    </Box>
  );
};

export default Workshops;
