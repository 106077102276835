import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Modal, TextField, useTheme } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { appTheme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../common/Button/PrimaryButton";
import { querySignIn, querySignUp } from "../../services/let-data-services";
import { Paper } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { Urls } from "../../interfaces/constants";
import {
  getSessionStorage,
  removeAllSessionStorage,
} from "../../utils/sessionStorage";
import { Storage } from "../../interfaces/constants";

const settings = ["Sign in"];
const authorizedSettings = ["Cart", "Sign out"];

const useStyles = makeStyles({
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    background: appTheme.palette.common.white,
    borderRadius: 5,
    padding: appTheme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  signUpButton: {
    margin: appTheme.spacing(4),
  },
});

type Props = {};

const MyAccount = ({}: Props) => {
  const { t } = useTranslation();
  const clx = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isSignInShown, setIsSignInShown] = useState<boolean>(true);
  const [menuOptions, setMenuOptions] = useState<string[]>(settings);
  const [open, setOpen] = useState<boolean>(false);
  const [fname, setFname] = useState<string>("");
  const [lname, setLname] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [tokenLogin, setTokenLogin] = useState(null);
  const [tokenLoginLoading, setTokenLoginLoading] = useState<boolean>(false);
  const [tokenLoginError, setTokenLoginError] = useState(null);
  const [tokenRegister, setTokenRegister] = useState(null);
  const [tokenRegisterLoading, setTokenRegisterLoading] =
    useState<boolean>(false);
  const [tokenRegisterError, setTokenRegisterError] = useState(null);
  const [authenticatedPath, setAuthenticatedPath] = useState<string>("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (location?.state) {
      const token = getSessionStorage("token");
      location.state?.path && setAuthenticatedPath(location.state?.path);
      location.state?.fromRequireAuth && !token && handleOpen();
    }
  }, [location]);

  useEffect(() => {
    const token = getSessionStorage(Storage.TOKEN);
    if (token) {
      setTokenLogin({ success: true });
      setTokenLoginError(false);
    }
  }, []);

  useEffect(() => {
    if (tokenLogin?.success && !tokenLoginError) {
      handleClose();
      setMenuOptions(authorizedSettings);
      authenticatedPath && navigate(authenticatedPath);
    }
  }, [tokenLogin, tokenLoginError]);

  useEffect(() => {
    if (tokenRegister?.success && !tokenRegisterError) {
      handleClose();
      authenticatedPath && navigate(authenticatedPath);
      // TODO: redirect to account page for email verification
    }
  }, [tokenRegister, tokenRegisterError]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (menuOption: string, index: number) => {
    switch (menuOption) {
      case "Sign in":
        handleOpen();
        break;
      case "Sign out":
        removeAllSessionStorage();
        setMenuOptions(settings);
        navigate(Urls.SMHomepage);
        break;
      case "Cart":
        navigate(Urls.Cart);
        break;
      default:
        break;
    }
    setAnchorElUser(null);
  };

  const handleSignIn = () => {
    if (email && password) {
      querySignIn(
        { email, password },
        setTokenLogin,
        setTokenLoginLoading,
        setTokenLoginError
      );
    }
  };

  const handleSignUp = () => {
    if (fname && lname && mobileNo && email && password) {
      querySignUp(
        { fname, lname, mobile: mobileNo, email, password },
        setTokenRegister,
        setTokenRegisterLoading,
        setTokenRegisterError
      );
    }
  };

  const renderSignIn = useMemo(
    () => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ mt: appTheme.spacing(4) }}>
          {t("login.signin")}
        </Typography>
        <Typography variant="subtitle2" sx={{ my: appTheme.spacing(4) }}>
          {t("login.signInHelperText")}
        </Typography>
        <Box>
          <TextField
            label={t("login.email")}
            variant="outlined"
            fullWidth
            type={"email"}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            label={t("login.password")}
            variant="outlined"
            fullWidth
            type={"password"}
            value={password}
            sx={{ mt: appTheme.spacing(4) }}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                "&:hover": {
                  color: appTheme.palette.primary.main,
                  cursor: "pointer",
                },
              }}
            >
              {t("login.forgetPassword")}
            </Typography>
          </Box>
          {tokenLoginError && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: appTheme.palette.error.main,
                }}
              >
                {t("login.errorSignin")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PrimaryButton className={clx.signUpButton} onClick={handleSignIn}>
            {t("login.signin")}
          </PrimaryButton>
          <Typography>{t("login.or")}</Typography>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.secondary.main,
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
            onClick={() => setIsSignInShown(false)}
          >
            {t("login.signup")}
          </Typography>
        </Box>
      </Box>
    ),
    [email, password, tokenLoginError]
  );

  const renderSignUp = useMemo(
    () => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ my: appTheme.spacing(4) }}>
          {t("login.signup")}
        </Typography>
        <Box sx={{ width: "55%" }}>
          <TextField
            label={t("login.fname")}
            variant="outlined"
            fullWidth
            required
            value={fname}
            onChange={(event) => setFname(event.target.value)}
          />
          <TextField
            sx={{ my: appTheme.spacing(4) }}
            label={t("login.lname")}
            variant="outlined"
            fullWidth
            value={lname}
            onChange={(event) => setLname(event.target.value)}
          />
          <TextField
            label={t("login.mobileNumber")}
            variant="outlined"
            fullWidth
            value={mobileNo}
            onChange={(event) => setMobileNo(event.target.value)}
          />
          <TextField
            sx={{ my: appTheme.spacing(4) }}
            label={t("login.email")}
            variant="outlined"
            fullWidth
            type={"email"}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            label={t("login.password")}
            variant="outlined"
            fullWidth
            type={"password"}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          {tokenRegisterError && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: appTheme.palette.error.main,
                }}
              >
                {tokenRegisterError.data.error.email
                  ? tokenRegisterError.data.error.email[0]
                  : tokenRegisterError.data.error.password
                  ? tokenRegisterError.data.error.password[0]
                  : tokenRegisterError.data.error.mobile
                  ? tokenRegisterError.data.error.mobile[0]
                  : t("login.error")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PrimaryButton className={clx.signUpButton} onClick={handleSignUp}>
            {t("login.signup")}
          </PrimaryButton>
          <Typography>{t("login.or")}</Typography>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.secondary.main,
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
            onClick={() => setIsSignInShown(true)}
          >
            {t("login.signin")}
          </Typography>
        </Box>
      </Box>
    ),
    [fname, lname, mobileNo, email, password, tokenRegisterError]
  );

  return (
    <Box>
      <Tooltip title="Account">
        <IconButton onClick={handleOpenUserMenu} size="small">
          <AccountCircleIcon color={"secondary"} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: theme.spacing(12) }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={() => handleCloseUserMenu("", -1)}
      >
        {menuOptions.map((setting, index) => (
          <MenuItem
            key={setting}
            onClick={() => handleCloseUserMenu(setting, index)}
          >
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <Modal open={open} onClose={handleClose}>
        <Paper className={clx.modal} elevation={5}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component="img"
              sx={{ width: "30%", mr: appTheme.spacing(4) }}
              src={require("../../assets/sy-logo.png")}
              alt={"sy-logo"}
            />
            <Typography variant="h5">
              {t("header.theLifeEternalTrustPune")}
            </Typography>
          </Box>
          {isSignInShown ? renderSignIn : renderSignUp}
        </Paper>
      </Modal>
    </Box>
  );
};
export default MyAccount;
