import React, {useState} from "react";
import { Box, Button, Container, createTheme, Tab, Tabs, Typography } from "@mui/material";
import Header from "../../components/header/Header";
import ImageCarousel from "../../components/ImageCarousel";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
});
const childhoodImage: { label: string; imgPath: string } = {
    label: "Image2",
    imgPath: "images/Shree-Mataji-Childhood-1.png",
  };
  const breakImage: { label: string; imgPath: string } = {
    label: "Image3",
    imgPath: "images/XMLID_1_.png",
  };
  const ShreeMatajiImage: { label: string; imgPath: string } = {
    label: "Image3",
    imgPath: "images/Shree-Mataji-photo-about.png",
  };
const About = () => {
    const theme = createTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();
    return (
        <>
        <Box sx={{ mx: theme.spacing(5), mt:theme.spacing(3), fontFamily:'Work Sans' , textAlign:'center' }}>
          <Typography variant="h3" >
            {t("header.aboutHerHolinessShreeMataji")}
          </Typography>
        </Box> 
        <Box
        component="img"
        
        sx={{ width: 1, mt: theme.spacing(2),  }}
        src={childhoodImage.imgPath}
        alt={childhoodImage.label}
      />
      <Box
        component="img"
        
        sx={{ width: 0.095,margin:'auto', mt: theme.spacing(2),display:'block' }}
        src={breakImage.imgPath}
        alt={breakImage.label}
      />
    
          <Typography variant="body2">
            {t("about.aboutHerHolinessShreeMatajiLine1")}
          </Typography>
            <br/>
          <Typography variant="body2">
            {t("about.aboutHerHolinessShreeMatajiLine2")}
          </Typography>
            <br/>
          <Typography variant="body2">
            {t("about.aboutHerHolinessShreeMatajiLine3")}
          </Typography>
            <br/>
          <Typography variant="body2">
            {t("about.aboutHerHolinessShreeMatajiLine4")}
          </Typography>
        <Box
        component="img"
        
        sx={{ width: 1, mt: theme.spacing(2), }}
        src={ShreeMatajiImage.imgPath}
        alt={ShreeMatajiImage.label}
      />
   
     
          <Typography variant="body2">
            {t("about.aboutHerHolinessShreeMatajiLine5")}
          </Typography>
          <br/>
          <Typography variant="body2">
            {t("about.aboutHerHolinessShreeMatajiLine6")}
          </Typography>
            <br/>
          <Typography variant="body2">
            {t("about.aboutHerHolinessShreeMatajiLine7")}
          </Typography>
          <Box
        component="img"
        
        sx={{ width: 0.15, margin:'auto', mt: theme.spacing(2),display:'block'}}
        src={breakImage.imgPath}
        alt={breakImage.label}
      />
      
        </>
    );
};

export default About;
