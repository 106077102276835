import React, {useState} from "react";
import { Box, Button, Container, createTheme, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import Header from "../../components/header/Header";
import ImageCarousel from "../../components/ImageCarousel";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
});
const image1: { label: string; imgPath: string } = {
    label: "Image1",
    imgPath: "images/Awards_1.jpg",
  };
  const image2: { label: string; imgPath: string } = {
    label: "Image1",
    imgPath: "images/Awards_2.jpg",
  };
  const breakImage: { label: string; imgPath: string } = {
    label: "Image3",
    imgPath: "images/XMLID_1_.png",
  };
  const ShreeMatajiImage: { label: string; imgPath: string } = {
    label: "Image3",
    imgPath: "images/Shree-Mataji-photo-about.png",
  };
const AwardsAndRecognitions = () => {
    const theme = createTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
        <Box sx={{ mx: theme.spacing(5), mt:theme.spacing(3), fontFamily:'Work Sans', textAlign: 'center'  }}>
            
          <Typography variant="h3" >
            {t("header.awardsAndRecognitions")}
          </Typography>
       
        </Box> 
        <Grid item container direction="row">
            <Grid item md={5}>
            <Box
        component="img"
        
        sx={{ width: 1, mt: theme.spacing(2),  }}
        src={image1.imgPath}
        alt={image1.label}
      />
            </Grid>
            <Grid item md={7} sx={!isMobile && {paddingLeft: `${theme.spacing(2)}`}}>
            <Typography variant="h4" sx={{ mt: theme.spacing(2) }}>
            {t("header.awards")}
          </Typography>
            <br/>
            <Typography variant="subtitle1">
            {t("awards.placeAndTime1")}
          </Typography>
          <Typography variant="body2" >
            {t("awards.description1")}
          </Typography>
          <br/>
          <Typography variant="subtitle1">
            {t("awards.placeAndTime2")}
          </Typography>
          <Typography variant="body2" >
            {t("awards.description2")}
          </Typography>
          <br/>
          <Typography variant="subtitle1">
            {t("awards.placeAndTime3")}
          </Typography>
          <Typography variant="body2" >
            {t("awards.description3")}
          </Typography>
          <br/>
          <Typography variant="subtitle1">
            {t("awards.placeAndTime4")}
          </Typography>
          <Typography variant="body2" >
            {t("awards.description4")}
          </Typography>
          <br/>
          <Typography variant="subtitle1">
            {t("awards.placeAndTime5")}
          </Typography>
          <Typography variant="body2" >
            {t("awards.description5")}
          </Typography>
          </Grid>
         </Grid>
         <br/>
         <Typography variant="subtitle1">
            {t("awards.placeAndTime6")}
          </Typography>
          <Typography variant="body2" >
            {t("awards.description6")}
          </Typography>
          <br/>
          <Typography variant="subtitle1">
            {t("awards.placeAndTime7")}
          </Typography>
          <Typography variant="body2" >
            {t("awards.description7")}
          </Typography>  
    
          <Box
        component="img"
        
        sx={{ width: 0.095, margin:'auto', mt: theme.spacing(2), display:'block' }}
        src={breakImage.imgPath}
        alt={breakImage.label}
      />
          <Box sx={{ mt:theme.spacing(3), fontFamily:'Work Sans',  }}>
            
          <Typography variant="h4" >
            {t("header.honors")}
          </Typography>
       
        </Box> 
        <Grid item container direction="row">
        <Grid item md={7} sx={{paddingRight: `${theme.spacing(2)}`}}>
            <br/>
            <Typography variant="subtitle1">
            {t("honors.placeAndTime1")}
          </Typography>
          <Typography variant="body2" >
            {t("honors.description1")}
          </Typography>
          <br/>
          <Typography variant="subtitle1">
            {t("honors.placeAndTime2")}
          </Typography>
          <Typography variant="body2" >
            {t("honors.description2")}
          </Typography>
          <br/>
          <Typography variant="subtitle1">
            {t("honors.placeAndTime3")}
          </Typography>
          <Typography variant="body2" >
            {t("honors.description3")}
          </Typography>
          
          <br/>

          <Typography variant="subtitle1">
            {t("honors.placeAndTime4")}
          </Typography>
          <Typography variant="body2" >
            {t("honors.description4")}
          </Typography>
          <br/>
          <Typography variant="subtitle1">
            {t("honors.placeAndTime5")}
          </Typography>
          <Typography variant="body2" >
            {t("honors.description5")}
          </Typography>
          
            </Grid>
            <Grid item md={5}>
            <Box
        component="img"
        
        sx={{ width: 1, mt: theme.spacing(2),  }}
        src={image2.imgPath}
        alt={image2.label}
      />
            </Grid>
            
        </Grid>
      
          <Box
        component="img"
        
        sx={{ width: 0.15, margin:'auto', mt: theme.spacing(2),display:'block'}}
        src={breakImage.imgPath}
        alt={breakImage.label}
      />
        </>
    );
};

export default AwardsAndRecognitions;
