import React, { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import WebFooter from "./WebFooter";
import MobileFooter from "./MobileFooter";
import { Urls } from "../../interfaces/constants";
import { useNavigate } from "react-router-dom";
import { fetchPujas, Puja } from "../../services/let-data-services";

const Footer = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const footerLinks = [
    "header.founder",
    "header.holyPlaces",
    "header.freeClasses",
    "header.contactUs",
    "header.donation",
    "header.sahajaMaterial",
  ];
  const externalLinks = [
    "https://www.thelifeeternaltrust.org",
    "https://www.sahajahealthcentre.com",
    "https://www.sahajayogamumbai.org",
    "https://www.pksacademy.com",
    "https://www.nirmaldham.org",
    "https://www.sahajaworldfoundation.org",
  ];
  const privacyPolicy = [
    "footer.privacyPolicy",
    "footer.returnRefunds",
    "footer.shippingPolicy",
    "footer.policyTnC",
  ];

  const [pujas, setPujas] = useState<Puja[]>([]);
  const [pujasLoading, setPujasLoading] = useState(false);
  const [pujasError, setPujasError] = useState(null);

  useEffect(() => {
    fetchPujas(setPujas, setPujasLoading, setPujasError);
  }, []);

  const handleClickOnHeaderButton = (index) => {
    if (index !== -1) {
      switch (index) {
        case 0:
          navigate(Urls.Homepage);
          break;
        case 1:
          navigate(Urls.HolyPlace);
          break;
        case 2:
          navigate(Urls.Homepage);
          break;
        case 3:
          navigate(Urls.Homepage);
          break;
        case 4:
          navigate(Urls.Homepage);
          break;
        case 5:
          navigate(Urls.SMHomepage);
          break;
        default:
          navigate(Urls.Homepage);
      }
    }
  };
  return isMatch ? (
    <MobileFooter
      footerLinks={footerLinks}
      externalLinks={externalLinks}
      privacyPolicy={privacyPolicy}
      pujas={pujas}
      handleClickOnHeaderButton={handleClickOnHeaderButton}
    />
  ) : (
    <WebFooter
      footerLinks={footerLinks}
      externalLinks={externalLinks}
      privacyPolicy={privacyPolicy}
      pujas={pujas}
      handleClickOnHeaderButton={handleClickOnHeaderButton}
    />
  );
};

export default Footer;
