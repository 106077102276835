import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Paper,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { fetchSMCategories, Puja } from "../../services/let-data-services";
import { appTheme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Urls } from "../../interfaces/constants";
import PrimarySquareButton from "../../components/common/Button/PrimarySquareButton";

const useStyles = makeStyles({
  productPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: `${appTheme.spacing(8)} ${appTheme.spacing(12)}`,
  },
  mobileProductPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: `${appTheme.spacing(0)} ${appTheme.spacing(0)}`,
  },
  categoryToolbar: {
    display: "flex",
    alignItems: "center",
  },
  categoriesDropdown: {
    width: "20%",
  },
  clearCategories: {
    alignItems: "center",
    marginLeft: appTheme.spacing(4),
  },
  divider: {
    border: `1px solid ${appTheme.palette.divider}`,
    marginTop: appTheme.spacing(4),
    marginBottom: appTheme.spacing(4),
  },
  productContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  productCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    margin: appTheme.spacing(2),
    borderRadius: 5,
  },
  mobileProductCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "44%",
    margin: appTheme.spacing(2),
    borderRadius: 5,
  },
  aboutSMContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: appTheme.spacing(12),
    marginBottom: appTheme.spacing(4),
  },
  aboutSM: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: appTheme.palette.primary.main,
    margin: `${appTheme.spacing(4)} 0`,
    padding: `0 ${appTheme.spacing(8)}`,
    width: "75%",
  },
  viewButton: {
    margin: appTheme.spacing(4),
  },
});

const smContent = `
  Welcome to Sahaja Material |Dear Sahaji Brother’s and Sisters, we welcome you all to Sahaja Material.
  Sahaja Material was established with a clean and clear aim of making Sahaja Material available to every Sahajayogi with minimum price in an easy and smooth way.|
  So, we are launching this website to meet Sahaja Material needs of sahajayogis for regular and puja essentials.
  |Pune Yuvashakti started manufacture & distribution of Sahaja Material products under the guidance of Life Eternal Trust, Pune. Sahaja material products are developed and managed by dedicated & devoted Yuvashakti volunteers from Pune.
  |Sahaja Material stalls are set up at every international/national pujas throughout Maharashtra. We are glad to announce “Sahaja Material” website has been launched with the aim of helping dear sahajayogis to get the benefit of Sahaja Material throughout India.
  The surplus gained by Sahaja Material is used for Prachar-Prasar activities, Nirmal Nagari Bhugaon Sahasrar Mandir development, Kothrud ashram development, and managing collectivity activities such as organizing seminars.
  `;

const SMHome = () => {
  const clx = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState<Puja[]>([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categoriesError, setCategoriesError] = useState(null);

  useEffect(() => {
    fetchSMCategories(setCategories, setCategoriesLoading, setCategoriesError);
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value as string);
  };

  const handleClearCategory = (_) => {
    setSelectedCategory("");
  };

  const handleViewProduct = (category) => {
    if (category && categories) {
      navigate(Urls.Shop, {
        state: { selectedCategory: category, categories: categories },
      });
    }
  };

  const renderProductCategories = useMemo(
    () => (
      <Box className={isMobile ? clx.mobileProductPage : clx.productPage}>
        <Typography textAlign="center" variant="h6">
          {t("smHome.sahajaMaterialCategoty")}
        </Typography>
        <Box className={clx.productContainer}>
          {categories?.map((category) => (
            <Paper
              className={isMobile ? clx.mobileProductCard : clx.productCard}
              elevation={4}
            >
              <Box
                component="img"
                sx={
                  isMobile
                    ? { width: 1, height: "10rem", objectFit: "contain" }
                    : { width: 1, height: "20rem", objectFit: "contain" }
                }
                src={category.image}
                alt={category.cat_name}
              />
              <Typography textAlign="center" variant="h6">
                {category.cat_name}
              </Typography>
              <PrimarySquareButton
                className={clx.viewButton}
                onClick={() => handleViewProduct(category)}
              >
                {isMobile
                  ? t("smHome.view")
                  : t("smHome.view").concat(category.cat_name)}
              </PrimarySquareButton>
            </Paper>
          ))}
        </Box>
        <Box className={clx.aboutSMContainer}>
          <Typography textAlign="center" variant="h6">
            {t("smHome.aboutSM")}
          </Typography>
          <Paper className={clx.aboutSM}>
            <Box
              component="img"
              sx={
                isMobile
                  ? {
                      width: "40%",
                      transform: "rotate(180deg)",
                      marginY: appTheme.spacing(4),
                    }
                  : {
                      width: "20%",
                      transform: "rotate(180deg)",
                      marginY: appTheme.spacing(4),
                    }
              }
              src={require("../../assets/separator.png")}
              alt={""}
            />
            <Typography textAlign="center" variant="h6">
              {smContent.split("|").map((text) => (
                <>
                  <Typography textAlign="center" variant="subtitle1">
                    {text}
                  </Typography>
                  <br />
                </>
              ))}
            </Typography>
            <Box
              component="img"
              sx={
                isMobile
                  ? { width: "40%", marginY: appTheme.spacing(4) }
                  : { width: "20%", marginY: appTheme.spacing(4) }
              }
              src={require("../../assets/separator.png")}
              alt={""}
            />
          </Paper>
        </Box>
      </Box>
    ),
    [isMobile, categories]
  );
  return (
    <>
      {/* hero card */}
      <Box
        component="img"
        sx={{ width: 1 }}
        src={require("../../assets/holy_place_hero_card.png")}
        alt={"holy_place_hero_card"}
      />
      {/* product categories */}
      {renderProductCategories}
    </>
  );
};

export default SMHome;
