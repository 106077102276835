import React, { Suspense } from "react";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { appTheme } from "./styles/theme";
import AppRouting from "./AppRoutingComponent";
import { Box, Toolbar } from "@mui/material";
import Header from "./components/header/Header";
import Footer from "./components/footer";
import "./App.scss";

const App = () => (
  <Suspense fallback="loading">
    <ThemeProvider theme={responsiveFontSizes(appTheme)}>
      <BrowserRouter>
        <Header />
        <Box sx={{ mt: "1.8rem" }}></Box>
        <Toolbar />
        <AppRouting />
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  </Suspense>
);

export default App;
