import React from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import allRoutes from "./routes";

const getRoute = (
  isNavigationMenuRoute: boolean,
  path: string,
  Component: any,
  index: number,
  requireAuth: boolean,
  accessible: boolean
) => (
  <Route
    key={`route_${index}`}
    path={path}
    element={
      isNavigationMenuRoute ? (
        requireAuth ? (
          <RequireAuth accessible={accessible}>
            <Component key={Date.now()} />
          </RequireAuth>
        ) : (
          <Component key={Date.now()} />
        )
      ) : requireAuth ? (
        <RequireAuth accessible={accessible}>
          <Component />
        </RequireAuth>
      ) : (
        <Component />
      )
    }
  />
);

export const pathToComponent = Object.keys(allRoutes).reduce(
  (pathCollector: any, path: string, index: number) => {
    const routeObj = allRoutes[path];
    const isNavigationMenuRoute = routeObj.hasOwnProperty(
      "isNavigationMenuItem"
    )
      ? routeObj.isNavigationMenuItem
      : false;
    const requireAuth = routeObj.hasOwnProperty("requireAuth")
      ? routeObj.requireAuth
      : false;
    const accessible = routeObj.hasOwnProperty("accessible")
      ? routeObj.accessible
      : true;
    pathCollector[path] = getRoute(
      isNavigationMenuRoute,
      path,
      routeObj.component,
      index,
      requireAuth,
      accessible
    );
    return pathCollector;
  },
  {}
);

const generateRoutes = () => {
  const routes = allRoutes;
  const routesCollector = [];
  Object.keys(routes).forEach((path: string) => {
    routesCollector.push(pathToComponent[path]);
  });
  return routesCollector;
};

const AppRoutingComponent = () => <Routes>{generateRoutes()}</Routes>;

export default AppRoutingComponent;
