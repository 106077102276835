import { Box, Typography, createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const ShippingPolicy = () => {
  const theme = createTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //textAlign: "left",
        //mx: theme.spacing(4),
        //mt: theme.spacing(4),
        mb: theme.spacing(2),
        mt: theme.spacing(1),
        pl: "2%",
        pb: "2%",
      }}
    >
      <Typography variant="h3">Shipping Policy</Typography>
      <br />
      <div>
        <p>&nbsp;</p>
        <h2>Shipping Policy</h2>
        <p>&nbsp;</p>
        <p>
          Nirmal Sahitya LLP is not responsible for the Shipping. Shipping is
          handled is by Shyplite
        </p>
        <p>
          Delivery Timelines:-
          <br />
          Minimum :- 3 days
          <br />
          Maximum:- 3 weeks
          <br />
          In case for pandemic and unforeseen situations these timelines may
          changes
        </p>
        <p>Shyplite Terms of Use:-</p>
        <ol>
          <li>
            <strong>
              <b> Background</b>
            </strong>
          </li>
        </ol>
        <p>
          This document is an electronic record in terms of (i) Information
          Technology Act, 2000; (ii) the rules framed there under as applicable;
          and (iii) the amended provisions pertaining to electronic records in
          various statutes as amended by the Information Technology Act, 2000.
          This electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </p>
        <p>
          This document is published in accordance with the provisions of Rule 3
          (1) of the Information Technology (Intermediaries guidelines) Rules,
          2011 that require publishing the rules and regulations, privacy policy
          and Terms of Use for access or usage of the Platform (defined below).
        </p>
        <p>
          Shyplite (“Website” or “Platform”), is owned, registered and operated
          by Simba Logistics Private Limited (“Company”), a private limited
          company, incorporated under the provisions of the Companies Act, 1956,
          and having its registered office at W5-B/3, Western Avenue, Sainik
          Farms, Delhi- 110062.
        </p>
        <p>
          These terms of usage (“Terms of Use”) govern Your use of the Platform
          and Services (as defined below) as aggregated through the Platform. By
          using or visiting our Platform, or by using any Services aggregated
          through the Platform, You shall be deemed to have read, understood and
          accepted to be bound by these Terms of Use.
        </p>
        <p>
          For the purpose of these Terms of Use, wherever the context so
          requires “You”, “Your” or “User” shall mean any person who uses the
          Platform for availing the Services. The term “We”, “Us”, “Our” shall
          mean the Company, its employees, and authorised agents that perform
          any services on the Company’s behalf.
        </p>
        <p>
          We reserve the right to make changes to these Terms of Use at any
          time. Any such modifications will become effective immediately upon
          posting to the Platform and Your continued use of the Platform, and/or
          the Services aggregated through the Platform constitutes Your
          agreement to such modifications. You agree to periodically review the
          current version of these Terms of Use as posted on the Platform.
        </p>
        <ol start={2}>
          <li>
            <strong>
              <b> Services</b>
            </strong>
          </li>
        </ol>
        <p>
          We are aggregators of logistic services. Through our Platform, We
          enable the User(s) to ship orders to multiple locations, without
          having to deal with multiple courier companies (“Service Providers”)
          (“Services”).
        </p>
        <p>For the purpose of these Terms of Use,</p>
        <p>
          “Applicable Law” shall mean any statute, law, regulation, ordinance,
          rule, judgment, notification, rule of common law, order, decree,
          bye-law, government approval, directive, guideline, requirement or
          other governmental restriction, or any similar form of decision of, or
          determination by, or any interpretation, policy or administration,
          having the force of law of any of the foregoing, by any Authority
          having jurisdiction over the matter in question, whether in effect as
          of the date of these Terms of Use or thereafter;
        </p>
        <p>
          “Authority” shall mean any national, state, provincial, local or
          similar government, governmental, regulatory or administrative
          authority, branch, agency, any statutory body or commission or any
          non-governmental regulatory or administrative authority, body or other
          organisation to the extent that the rules, regulations and standards,
          requirements, procedures or orders of such authority, body or other
          organisation that have the force of Applicable Law or any court,
          tribunal, arbitral or judicial body in India;
        </p>
        <p>
          “Customer Agreement” shall mean the agreement entered into/ that shall
          be entered into by and between the You and the Company, which shall
          clearly state the inter- se rights and obligations between You and the
          Company;
        </p>
        <p>
          In order to avail the Services aggregated through the Platform, the
          User(s) shall be required to register on the Platform and sign and
          agree to the terms and conditions as mentioned in the Customer
          Agreement.
        </p>
        <p>
          You agree, inter alia, to provide true, accurate, current and complete
          information about yourself, as prompted by the Platform registration
          form as well as in the Customer Agreement. If You provide any
          information that is untrue, inaccurate, not current or incomplete or
          We have reasonable grounds to suspect that such information is untrue,
          inaccurate, not current, incomplete or that You are misusing the
          Platform in any manner, We reserve the right to indefinitely suspend,
          terminate or block access to the Platform and the Services aggregated
          through the Platform.
        </p>
        <ol start={3}>
          <li>
            <strong>
              <b> Platform to connect the Users with the Service Providers</b>
            </strong>
          </li>
        </ol>
        <p>
          We are merely aggregators between the Service Providers and the Users.
          These Terms of Use should not be construed in any way to mean that We
          provide such Services herein.
        </p>
        <p>
          We do not make any representation or warranty as to the timeliness
          and/or the quality of the Services to be provided by the Service
          Provider to the User. We do not accept liability for any errors or
          omissions committed by the Service Provider or their authorised
          personnel.
        </p>
        <p>
          The Service Providers are not employees or agents of the Company and
          the Company shall not be held liable for their actions or inactions.
        </p>
        <ol start={4}>
          <li>
            <strong>
              <b>
                {" "}
                Representations, Warranties and Obligations of the Platform
              </b>
            </strong>
          </li>
        </ol>
        <p>We hereby represent and warrant that:</p>
        <p>
          We are a duly registered company as under the relevant provisions of
          the The Limited Liability Partnership Act, 2008;
        </p>
        <p>
          We are in compliance with the Applicable Law to provide Services,
          subject to these Terms of Use;
        </p>
        <p>
          We will comply with the Privacy Policy and ensure data security of the
          Users at all times.
        </p>
        <ol start={5}>
          <li>
            <strong>
              <b> Representations, Warranties and Obligations of the User</b>
            </strong>
          </li>
        </ol>
        <p>You hereby represent and warrant that:</p>
        <p>
          You are a validly incorporated business entity or is legally competent
          to contract, as applicable;
        </p>
        <p>
          You shall comply with and fully adhere to these Terms of Use and the
          terms and conditions of the Customer Agreement;
        </p>
        <p>
          You shall provide accurate information and details, when prompted by
          the Platform registration form and in the Customer Agreement;
        </p>
        <p>
          You shall fulfil Your payment obligations for availing the Services
          through the Platform.
        </p>
        <ol start={6}>
          <li>
            <strong>
              <b> Intellectual Property Rights</b>
            </strong>
          </li>
        </ol>
        <p>Copyright</p>
        <p>
          All content included on the Platform, including but not limited to,
          text, graphics, logos, designs, photographs, button icons, images,
          video clips, digital downloads, data compilations etc., is Our
          property and is protected by the Applicable Laws with respect to
          intellectual property rights. We reserve the right to terminate Your
          engagement with Us, if We, in Our sole and absolute discretion,
          believe that You are in violation of this Clause.
        </p>
        <p>
          The content made available on or via the Platform, is provided to You
          ‘AS IS’ for Your information and personal use only and may not be
          used, copied, reproduced, distributed, transmitted, broadcast,
          displayed, sold, licensed, or otherwise exploited for any other
          purposes whatsoever without Our prior written consent. We reserve all
          rights, not expressly granted in and to the Platform.
        </p>
        <p>Trademarks</p>
        <p>
          shyplite.com is the domain of the Company. The Platform, including,
          but not limited to its graphics, logos, page headers, button icons,
          scripts and service names constitute trade dress of the Company. The
          trademarks, domain names and trade dress of the Company shall not be
          used or reproduced without prior written approval from the Company,
          and may not be used in connection with any product or service that is
          not affiliated with the Company.
        </p>
        <ol start={7}>
          <li>
            <strong>
              <b> User feedback/comments/ suggestions</b>
            </strong>
          </li>
        </ol>
        <p>
          While rating/ submitting/ posting comments/ suggestions/ opinions/
          feedback etc. (“User Feedback”), the User agrees and acknowledges
          that:
        </p>
        <p>
          The User Feedback does not contain any confidential information or is
          not in violation of any third party right including intellectual
          property rights;
        </p>
        <p>
          The User Feedback shall not be unlawful, obscene, defamatory,
          libelous, threatening, pornographic, harassing, hateful, racially or
          ethnically offensive, or is otherwise inappropriate;
        </p>
        <p>
          We are not under any obligation of confidentiality, express or
          implied, regarding the User Feedback;
        </p>
        <p>
          We reserve the right to use or disclose such User Feedback for any
          purpose, in any way, as We deem fit;
        </p>
        <p>
          By posting/ submitting User Feedback, the same shall become our
          intellectual property right without any obligations including but not
          limited to any compensation or consideration, express or implied to
          You.
        </p>
        <ol start={8}>
          <li>
            <strong>
              <b> Privacy</b>
            </strong>
          </li>
        </ol>
        <p>
          We collect, store, process and use Your information in accordance with
          Our Privacy Policy. By using the Platform and/ or by providing Your
          information, You consent to the collection and use of the information
          You disclose on the Platform in accordance with Our Privacy Policy.
        </p>
        <ol start={9}>
          <li>
            <strong>
              <b> Fraud and Improper Conduct</b>
            </strong>
          </li>
        </ol>
        <p>
          You may only access the Platform and use the Services for lawful
          purposes. You are solely responsible for the knowledge of and
          adherence to any and all provisions of Applicable Law pertaining to
          Your use of the Services. You agree that You will not in any way:
        </p>
        <p>
          Interfere with the ability of others to access or use the Platform and
          the Services aggregated through the Platform;
        </p>
        <p>
          Disrupt the normal flow of communication or otherwise act in a manner
          that adversely affects other Users ability to use the Platform or the
          Services;
        </p>
        <p>
          Interfere with or disrupt the Services or servers or networks
          connected to the Services, or disobey any requirements, procedures,
          policies, or regulations of networks connected to the Services;
        </p>
        <p>
          Upload or post or transfer, any content or other material that
          contains or constitutes viruses, Trojan horse or other code with
          malicious, disruptive and/or destructive features;
        </p>
        <p>
          You shall not attempt to interfere with any other User’s use of the
          Platform or the Services aggregated through the Platform;
        </p>
        <p>
          You shall not use any false or misleading information (e.g., false or
          misleading names, email addresses or URLs) when using the Service,
          including, without limitation, with respect to any identifying
          information about yourself, and all information that You provide must
          be accurate and correct.
        </p>
        <ol start={10}>
          <li>
            <strong>
              <b> Limitation and Disclaimer of Warranty</b>
            </strong>
          </li>
        </ol>
        <p>
          The Platform, the Services and each portion thereof are provided “AS
          IS” without warranties of any kind either express or implied. To the
          fullest extent possible pursuant to Applicable Law, We disclaim all
          warranties, express or implied, with respect to the Platform, the
          Services and each portion thereof, including, but not limited to,
          non-infringement or other violation of intellectual property rights.
        </p>
        <p>
          We do not warrant or make any representations regarding the use,
          validity, accuracy, or reliability of the Platform.
        </p>
        <p>
          We do not warrant or make any representations that the Platform shall
          (i) meet Your requirements or reliable; (ii) be uninterrupted, timely,
          secure or error-free; (iii) rectify any errors found on the Platform’s
          software. Further, access to the Platform shall be contingent to Your
          internet accessibility and We shall not be held liable for any lack/
          sporadic breaks in Your internet accessibility.
        </p>
        <p>
          We shall not be liable in the event any damage or loss occurs to your
          computer system, or any other electronic device, or any data as a
          result of visiting the Website.
        </p>
        <p>
          We do not warrant or make any representations regarding the
          reliability, suitability or quality of the Service provided by the
          Service Provider.
        </p>
        <p>
          We shall not be liable for any loss or damages suffered by the User
          due to performance or non-performance of the Services by the Service
          Provider. Further, We shall not be liable for any loss or damage
          suffered, on account of any fault, willful misconduct or negligence on
          the part of the Service Provider.
        </p>
        <p>
          We shall not be liable for any direct, indirect, incidental or
          consequential damages, whatsoever incurred by the User due to use of
          the Services or due to the non- availability of the Platform or the
          Services.
        </p>
        <p>
          If You are dissatisfied or harmed by the Platform or anything related
          with the Platform, Your sole remedy shall be to terminate these Terms
          of Use by leaving the Website.
        </p>
        <p>
          We shall be entitled to disclose to the Authority, as required by
          Applicable Law or by any directive or request from any government
          body, the particulars of the User engaged with the Platform.
        </p>
        <p>
          We shall be entitled to add, to vary or amend any or all these terms
          and conditions at any time and the User shall be bound by such
          addition, variation or amendment once such addition, variation or
          amendment is incorporated into these terms and conditions, and such
          amended terms and conditions are published on the Platform.
        </p>
        <ol start={11}>
          <li>
            <strong>
              <b> Indemnification</b>
            </strong>
          </li>
        </ol>
        <p>
          You undertake to indemnify Us, for any losses or damages resulting
          from any third party claims or complaints arising from, or in
          connection with Your actions on the Platform, and/or breach of these
          Terms of Use.
        </p>
        <ol start={12}>
          <li>
            <strong>
              <b> Termination</b>
            </strong>
          </li>
        </ol>
        <p>
          We may terminate these Terms of Use with respect to You, immediately
          without notice in the event of any breach by You of these Terms of Use
          or any of our applicable policies, as posted on the Platform from time
          to time or upon a misuse of the Services by You.
        </p>
        <p>
          You agree that upon the termination of these Terms of Use, We may
          delete all information related to You with respect to the Services
          availed by you. Further, You will no longer be able to access the
          Platform.
        </p>
        <ol start={13}>
          <li>
            <strong>
              <b> Severability</b>
            </strong>
          </li>
        </ol>
        <p>
          If any part of the Terms of Use are determined to be invalid or
          unenforceable pursuant to Applicable Laws, including, but not limited
          to, the warranty disclaimers and liability limitations set forth
          above, then the invalid or unenforceable provision will be deemed to
          be superseded by a valid, enforceable provision that most closely
          matches the intent of the original provision and the remainder of the
          Terms of Use for Services shall continue in effect.
        </p>
        <ol start={14}>
          <li>
            <strong>
              <b> General</b>
            </strong>
          </li>
        </ol>
        <p>
          Unless otherwise specified herein, these Terms of Use for Services
          aggregated through the Platform and the Privacy Policy (provided on
          the Platform) constitutes the entire agreement between you and the
          Platform, in respect of the Services and supersedes all previous
          written and oral agreements between You and the Platform, if any. Our
          failure to act with respect to a breach by you or others does not
          waive Our right to act with respect to subsequent or similar breaches.
        </p>
        <ol start={15}>
          <li>
            <strong>
              <b> Governing Law</b>
            </strong>
          </li>
        </ol>
        <p>
          Terms of Use shall be governed by and constructed in accordance with
          the Applicable Law without reference to conflict of laws principles.
          The courts{" "}
          <strong>
            <b>in Pune, Maharashtra</b>
          </strong>
          &nbsp;shall have the exclusive jurisdiction to determine any disputes
          arising in relation to, or under, these Terms of Use.
        </p>
      </div>
    </Box>
  );
};

export default ShippingPolicy;
