import { Box, Typography, createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const RefundPolicy = () => {
  const theme = createTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //textAlign: "left",
        //mx: theme.spacing(4),
        //mt: theme.spacing(4),
        mb: theme.spacing(2),
        mt: theme.spacing(1),
        pl: "2%",
        pb: "2%",
      }}
    >
      <Typography variant="h3">Refund Policy</Typography>
      <br />
      <Typography>
        Nirmal Sahitya LLP believes in Centum Customer satisfaction and “no
        questions asked return and refund policy” for issues relating to
        quality. Refunds and Cancellation can only be requested within an hour
        of placing the order. After the request 2% of the amount will be
        deducted from the payment and rest 98% of the payment will processed in
        your bank account within 48 hours and will be credited to the customer
        account within 5-7 working days depending on the issuing bank. The
        refund policy applied from 7 days if the Client is unsatisfied or having
        any problem in the Product so it will be replaced as soon as possible
        after receiving back the parcle. Freight Charges for the refund will be
        bared by Nirmal Sahitya LLP.
      </Typography>
    </Box>
  );
};

export default RefundPolicy;
