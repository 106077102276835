export const setSessionStorage = (key: string, value: string) => {
  if (key && value) sessionStorage.setItem(key, value);
};

export const getSessionStorage = (key: string) => {
  if (key) return sessionStorage.getItem(key);
};

export const getObjectSessionStorage = (key: string) => {
  if (key) return JSON.parse(sessionStorage.getItem(key));
};

export const setObjectSessionStorage = (key: string, value: any) => {
  if (key && value) sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeSessionStorage = (key: string) => {
  if (key) sessionStorage.removeItem(key);
};
export const removeAllSessionStorage = () => {
  sessionStorage.clear();
};
