import React, { useState, useCallback, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Urls } from "../../interfaces/constants";
import { appTheme } from "../../styles/theme";
import DrawerComp from "./AppDrawer";
import HeaderButton from "./HeaderButton";
import MyAccount from "./MyAccount";
import Marquee from "react-fast-marquee";
import { Features, fetchFeatures } from "../../services/let-data-services";

const useStyles = makeStyles({
  mobileHeaderContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  webHeaderContainerWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  webHeaderContainer: {
    marginX: "auto",
  },
  toolbar: {
    background: appTheme.palette.primary.main,
  },
  headerButton: {
    color: appTheme.palette.secondary.main,
  },
  activeHeaderButton: {
    color: appTheme.palette.secondary.main,
    textDecoration: "underline",
  },
  accountIcon: {
    position: "absolute",
    right: appTheme.spacing(4),
  },
});

const PAGES = [
  { title: "header.theLifeEternalTrustPune", path: Urls.Homepage },
  { title: "header.freeClasses", path: Urls.FreeClasses },
  { title: "header.holyPlaces", path: Urls.HolyPlace },
  { title: "header.events", path: Urls.Events },
  { title: "header.founder", path: Urls.Founder },
  { title: "header.contactUs", path: Urls.ContactUs },
  { title: "header.donation", path: Urls.Donation },
  { title: "header.sahajaMaterial", path: Urls.SMHomepage },
];

const Header = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallMatch = useMediaQuery(theme.breakpoints.down("sm"));

  const [features, setFeatures] = useState<Features>(null);
  const [featuresLoading, setFeaturesLoading] = useState<boolean>(false);
  const [featuresError, setFeaturesError] = useState<string>("");

  useEffect(() => {
    fetchFeatures(setFeatures, setFeaturesLoading, setFeaturesError);
  }, []);

  const handleClickOnHeaderButton = useCallback((index) => {
    if (index !== -1) {
      switch (index) {
        case 0:
          navigate(Urls.Homepage);
          break;
        case 1:
          navigate(Urls.FreeClasses);
          break;
        case 2:
          navigate(Urls.HolyPlace);
          break;
        case 3:
          navigate(Urls.Events);
          break;
        case 4:
          navigate(Urls.Founder);
          break;
        case 5:
          navigate(Urls.ContactUs);
          break;
        case 6:
          window.open(Urls.Donation);
          break;
        case 7:
          navigate(Urls.SMHomepage);
          break;
        default:
          navigate(Urls.Homepage);
      }
    }
  }, []);

  return (
    <AppBar>
      {features?.registration.enabled && (
        <Box sx={{ background: theme.palette.secondary.main }}>
          <Marquee gradient={false} speed={40}>
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.common.white,
                px: theme.spacing(4),
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                window.open(features.registration.url);
              }}
            >
              {features.registration.title}
            </Typography>
          </Marquee>
        </Box>
      )}
      <Toolbar className={classes.toolbar}>
        {isMatch ? (
          <Box className={classes.mobileHeaderContainer}>
            <Box
              component="img"
              sx={isSmallMatch ? { width: "10%" } : { width: "6%" }}
              src={require("../../assets/sy-logo.png")}
              alt={"sy-logo"}
              onClick={() => navigate(Urls.Homepage)}
            />
            <HeaderButton
              className={classes.headerButton}
              variant="text"
              disableRipple
              onClick={() => navigate(Urls.Homepage)}
            >
              {t("header.theLifeEternalTrustPune")}
            </HeaderButton>
            <DrawerComp />
          </Box>
        ) : (
          <Box className={classes.webHeaderContainerWrapper}>
            <Box
              component="img"
              sx={{ width: "3%", cursor: "pointer" }}
              src={require("../../assets/sy-logo.png")}
              alt={"sy-logo"}
              onClick={() => navigate(Urls.Homepage)}
            />
            <Box className={classes.webHeaderContainer}>
              {PAGES.map(
                (page: { title: string; path: string }, index: number) => (
                  <HeaderButton
                    className={
                      location.pathname === page.path
                        ? classes.activeHeaderButton
                        : classes.headerButton
                    }
                    variant="text"
                    disableRipple
                    onClick={() => {
                      handleClickOnHeaderButton(index);
                    }}
                  >
                    {t(page.title)}
                  </HeaderButton>
                )
              )}
            </Box>
            <Box className={classes.accountIcon}>
              {location.pathname.includes(Urls.SMHomepage) && <MyAccount />}
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
