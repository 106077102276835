import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  createTheme,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Header from "../../components/header/Header";
import ImageCarousel from "../../components/ImageCarousel";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import About from "./about";
import Vision from "./vision";
import AwardsAndRecognitions from "./awardsAndRecognitions";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../components/common/Accordian/Accordian";
import { useLocation } from "react-router-dom";
import { appTheme } from "../../styles/theme";

const useStyles = makeStyles({
  box: {
    width: "80%",
    margin: "auto",
    background: "#8E0024",
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
  },
  text: {
    color: "#FFCB78",
    textTransform: "none",
  },
  content: {
    margin: "auto",
    width: "80%",
  },
});
const image: { label: string; imgPath: string } = {
  label: "Image1",
  imgPath: "images/Banner-1.png",
};

const Founder = () => {
  const theme = createTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [contentIndex, setContentIndex] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    if (location && location.state && location.state.activeIndex) {
      setTabIndex(location.state.activeIndex);
    } else {
      setTabIndex(0);
    }
    if (location && location.state && location.state.activeTab) {
      setExpanded(location.state.activeTab);
    } else {
      setExpanded("weeklyCenter");
    }
  }, [location]);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            //textAlign: "left",
            alignItems: "center",
            //mx: theme.spacing(4),
            //mt: theme.spacing(4),
            mb: theme.spacing(2),
          }}
        >
          <Box
            component="img"
            sx={{ width: 1, height: 0.7 }}
            src={require("../../assets/holy_place_hero_card.png")}
            alt={"holy_place_hero_card"}
          />
          <Box className={classes.box}>
            <Tabs value={tabValue} onChange={handleTabChange} centered>
              <Tab
                className={classes.text}
                onClick={() => setContentIndex(0)}
                label="About Her Holiness Shree Mataji"
              />
              <Tab
                className={classes.text}
                onClick={() => setContentIndex(1)}
                label="Vision"
              />
              <Tab
                className={classes.text}
                onClick={() => setContentIndex(2)}
                label="Awards and Recognitions"
              />
            </Tabs>
          </Box>
          <Box className={classes.content}>
            <Box sx={{ padding: `${theme.spacing(2)}` }}>
              {contentIndex == 0 ? (
                <>
                  <About />
                </>
              ) : contentIndex == 1 ? (
                <Vision />
              ) : contentIndex == 2 ? (
                <AwardsAndRecognitions />
              ) : null}
            </Box>
          </Box>
        </Box>
      )}
      {isMobile && (
        <Box sx={{ mb: `${appTheme.spacing(10)}` }}>
          <Box
            component="img"
            sx={{ width: "100%" }}
            src={require("../../assets/holy_place_hero_card.png")}
            alt={"holy_place_hero_card"}
          />
          <Accordion
            expanded={expanded === "about"}
            onChange={handleChange("about")}
            sx={{ background: "#8E0024" }}
          >
            <AccordionSummary id={"about"}>
              <Typography
                variant="subtitle2"
                sx={{ color: appTheme.palette.primary.main }}
              >
                {"About Her Holiness Shree Mataji"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white" }}>
              <About />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "vision"}
            onChange={handleChange("vision")}
            sx={{ background: appTheme.palette.secondary.main }}
          >
            <AccordionSummary id={"vision"}>
              <Typography
                variant="subtitle2"
                sx={{ color: appTheme.palette.primary.main }}
              >
                {"Vision"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white" }}>
              <Vision />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "awards"}
            onChange={handleChange("awards")}
            sx={{ background: appTheme.palette.secondary.main }}
          >
            <AccordionSummary id={"awards"}>
              <Typography
                variant="subtitle2"
                sx={{ color: appTheme.palette.primary.main }}
              >
                {"Awards and Recognitions"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white" }}>
              <AwardsAndRecognitions />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </div>
  );
};

export default Founder;
