import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SOCIALS, Urls } from "../../interfaces/constants";
import { makeStyles } from "@material-ui/core/styles";
import { appTheme } from "../../styles/theme";
import HeaderButton from "../header/HeaderButton";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../common/Accordian/Accordian";

const useStyles = makeStyles({
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    background: appTheme.palette.secondary.main,
    alignItems: "center",
    padding: "1rem",
  },
  footerAccordian: {
    display: "flex",
    flexDirection: "column",
  },
  footerLETHeader: {
    color: appTheme.palette.primary.main,
    padding: `${appTheme.spacing(1)} ${appTheme.spacing(0)}`,
  },
  accordianContainer: {
    width: "100%",
    background: appTheme.palette.secondary.main,
  },
  socialContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  socialIcons: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingBottom: appTheme.spacing(2),
  },
  externalLink: {
    color: appTheme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  footerLink: {
    color: appTheme.palette.primary.main,
    fontWeight: 500,
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const MobileFooter = ({
  footerLinks,
  externalLinks,
  handleClickOnHeaderButton,
  privacyPolicy,
  pujas,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const renderPagesAccordian = useMemo(
    () => (
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            backgroundColor: appTheme.palette.secondary.main,
          }}
        >
          <AccordionSummary id={"panel1"}>
            <Typography
              className={classes.footerLink}
              onClick={() => {
                window.open("founder", "_self");
              }}
            >
              Founder
            </Typography>
            <Typography
              className={classes.footerLink}
              onClick={() => {
                window.open("holy-places", "_self");
              }}
            >
              Holy Places
            </Typography>
            <Typography
              className={classes.footerLink}
              onClick={() => {
                window.open("free-classes", "_self");
              }}
            >
              Free Classes
            </Typography>
            <Typography
              className={classes.footerLink}
              onClick={() => {
                window.open("contact-us", "_self");
              }}
            >
              Contact Us
            </Typography>
            <Typography
              className={classes.footerLink}
              onClick={() => {
                window.open("donation", "_self");
              }}
            >
              Donation
            </Typography>
            <Typography
              className={classes.footerLink}
              onClick={() => {
                window.open("sahaja-material", "_self");
              }}
            >
              Sahaja Material
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {footerLinks.map((page: string, index: number) => (
                <Typography
                  className={classes.footerLink}
                  onClick={() => {
                    setExpanded(false);
                    handleClickOnHeaderButton(index);
                  }}
                >
                  {t(page)}
                </Typography>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    ),
    [expanded]
  );

  const renderExternalLinksAccordian = useMemo(
    () => (
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{
            backgroundColor: appTheme.palette.secondary.main,
          }}
        >
          <AccordionSummary id={"panel2"}>
            <Typography variant="subtitle2" className={classes.footerLink}>
              {t("labels.sahajayogaLinks")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {externalLinks.map((link) => (
                <Typography
                  className={classes.externalLink}
                  onClick={() => {
                    window.open(link);
                  }}
                >
                  {link.replace("https://", "")}
                </Typography>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    ),
    [expanded]
  );

  const renderUpcomingEventsAccordian = useMemo(
    () => (
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{
            backgroundColor: appTheme.palette.secondary.main,
          }}
        >
          <AccordionSummary id={"panel3"}>
            <Typography variant="subtitle2" className={classes.footerLink}>
              {t("labels.upcomingEvents")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              className={classes.externalLink}
              onClick={() =>
                navigate(Urls.Events, {
                  state: { activeTab: "upcomingPuja", activeIndex: 1 },
                })
              }
            >
              {
                pujas?.find(
                  (puja) =>
                    new Date() < new Date(puja.puja_date) &&
                    puja.type === "puja"
                )?.puja_name
              }
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    ),
    [expanded]
  );

  const renderPolicyAccordian = useMemo(
    () => (
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{
            backgroundColor: appTheme.palette.secondary.main,
          }}
        >
          <AccordionSummary id={"panel4"}>
            <Typography variant="subtitle2" className={classes.footerLink}>
              {t("footer.termsCondition")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Typography
                className={classes.footerLink}
                onClick={() => {
                  window.open("terms-and-conditions");
                }}
              >
                {t("footer.termsCondition")}
              </Typography>
              <Typography
                className={classes.footerLink}
                onClick={() => {
                  window.open("shipping-policy");
                }}
              >
                Shipping Policy
              </Typography>
              <Typography
                className={classes.footerLink}
                onClick={() => {
                  window.open("refund-policy");
                }}
              >
                Refund Policy
              </Typography>
              <Typography
                className={classes.footerLink}
                onClick={() => {
                  window.open("privacy-policy");
                }}
              >
                Privacy Policy
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    ),
    [expanded]
  );

  return (
    <Box className={classes.footer} component="footer">
      <Box className={classes.socialContainer}>
        <Box
          component="img"
          src={require("../../assets/sy-logo.png")}
          maxWidth="6rem"
        />
        <HeaderButton
          className={classes.footerLETHeader}
          variant="text"
          color={"info"}
          disableRipple
          onClick={() => navigate(Urls.Homepage)}
        >
          {t("header.theLifeEternalTrustPune")}
        </HeaderButton>
        <Box className={classes.socialIcons}>
          {/* <InstagramIcon htmlColor="#fb3958" /> */}
          <FacebookIcon
            htmlColor="#3b5998"
            onClick={() => {
              window.open(SOCIALS.FaceBook);
            }}
          />
          <YouTubeIcon
            htmlColor="#c4302b"
            sx={{ mx: appTheme.spacing(4) }}
            onClick={() => {
              window.open(SOCIALS.Youtube);
            }}
          />
          <WhatsAppIcon
            htmlColor="#4FCE5D"
            onClick={() => {
              window.open(SOCIALS.WhatsApp);
            }}
          />
        </Box>
      </Box>
      {renderPagesAccordian}
      {renderExternalLinksAccordian}
      {renderUpcomingEventsAccordian}
      {location.pathname === Urls.SMHomepage && renderPolicyAccordian}
    </Box>
  );
};

export default MobileFooter;
