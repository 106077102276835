import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { appTheme } from "../../styles/theme";
import HolyPlaceCard from "./HolyPlaceCard";
import HolyPlaceModal from "./HolyPlaceModal";
import MobileHolyPlaceModal from "./MobileHolyPlaceModal";
import { HolyPlaceModel } from "../../interfaces/interfaces";

const useStyles = makeStyles({
  homeContainer: {},
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: `${appTheme.spacing(8)} ${appTheme.spacing(8)}`,
  },
  holyPlaceModal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "background.paper",
    border: "2px solid #000",
    boxShadow: "24",
    padding: 4,
  },
});

const HolyPlace = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [holyPlaces, setHolyPlaces] = useState<HolyPlaceModel[]>([]);
  const [selectedHolyPlace, setSelectedHolyPlace] = useState(null);
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {}, [isMobile]);

  useEffect(() => {
    const places = [
      {
        img: require("../../assets/holyplace-bhugoan-2.jpg"),
        name1: "Nirmal Nagari",
        name2: "Bhugoan Pune",
        description: `Nirmal Nagari: The Holy land for Sahaja Yogis
        This part of the land was acquired by the Pune Sahaja collective in a phased manner a few years ago. It has a lush green backdrop with a rugged topography of hilly terrain. The area is spread over thirteen acres of greenery. The place has the blessings of witnessing 5 international Puja seminars besides hosting a number of Nirakar Puja and also seminars and meetings. Myth and Historial Importance behind Nirmal Nagari
        The land is blessed as the myth goes and more importantly this site of Nirmal Nagari had been advised by Shree Mataji Herself especially envisioning the scope of hosting the international pujas.
        
        It is said that thousands of years ago in the Treta Yuga ( an era as per Indian calendar) that Shri Vishnu in His Shri Rama Incarnation incarnated along with Shri Sita as Shri Adi Shakti incarnate. The imprints of the myth speak of those events in this part of the land. As per the records of evidences and belief of the land dwellers in this part of the area, they claims that- Shri Sita and Shri Rama had come and spent a few important days on top of one of the hillocks immediately behind Nirmal Nagari, during the fourteen years of exile. It is also believed that it was in this part of India – Maharastra only, that Shri Ram and Shri Sita walked bare foot. Villagers in this part of the area in Khatpewadi claims, the remnant or fossilzed remains of the utensils used by Shri Rama and Shri Sita can still be traced in the neighboring hillocks that still bear the imprints as a proof of their holy visit. For the realized souls the vibrations on this land speak for its own importance. The Nirmal Nagari covers about thirteen acres of this holy land.
        
        Shree Mataji once enlightened  Her children narrating the story of Shri Rama’s advent and His Maharashtra tour bare foot. In Her teachings She reveals that as this part of land has to vibrated since Shri Ganesha Himself expresses on this land as the Earth element. So, He had to walk without His sandals that were carried back home by Shri Bharata His brother.`,
        significance: `“Because first of all when human beings came on this earth they were frightened of all the animals who would invade them and all kinds of horrible Rakshasas and all those people, and negative forces would trouble them. In that state the needed to create a king, a ruler, who was an ideal king and who would rule according to the laws of dharma. So he was the one in charge of that. He was here in Treta Yuga and Krishna came at the time of Dwapar Yuga. When I came it was Kali Yuga but now today the time is of Krita Yuga. The Yuga where work will be done – Krita Yuga. This is the time where work will be done” – H.H. Shree Mataji, Chelsham Road, London, 2nd April 1982`,
        location: {
          address: "Nirmal Nagari, Khatpewadi, Bhugaon, Pune",
          contact: "+91 91687 53232",
          nearestRailwayStation: "Pune",
          nearestAirport: "Pune",
          website: "http://thelifeeternaltrustpune.org/",
          mapUrl:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.2219354898034!2d73.712310614842!3d18.47360368743634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bd885b1df1ef%3A0x605fdf55f23859e0!2sNirmal%20Nagari%20Khatpewadi%20Bhugaon!5e0!3m2!1sen!2sin!4v1639330221994!5m2!1sen!2sin",
        },
      },
      {
        img: require("../../assets/holy_place_1.png"),
        name1: "Nirmal Dham",
        name2: "Adishakti Peeth Delhi",
        description: `It was indeed a historical and golden moment for Delhi’s Nirmalites when The Divine Herself advised them to have a plot of land to make an ashram for their collectivity which She forecasted to grow tremendously. The Delhi Sahaja Yogis took it up as a mission to achieve.|The yogis started to search for the appropriate place to accomplish the desired goal. After a couple of months’ search, a five acre plot of land available for sale was shortlisted and the pictures of the same with all geographical and other descriptions were presented to Shri Mataji for Her approval. The Divine Mother went through the proposal and immediately accorded Her permission to go ahead. With all the Divine Blessings, the proposal to buy the available piece of five acre land was materialized. Soon thereafter, another piece of two and a half acre of land, adjoining the acquired area also came up for sale and the same was also settled and purchased by Her grace. After taking over the possession of land, the Sahaja Yogis requested Shri Mataji to bless and name the proposed ashram to be opened on this auspicious land. The Divine Mother then described its significance as a “Dham” i.e. “Divine Abode”. Hence, the Name “Nirmal Dham” was christened.`,
        significance: `When Shri Gauri in Her Sati incarnation got enraged by the insult of Shri Shiva by Yaksha Prajapati, She burnt herself in yajna of Yaksha – Her father. In the state of anger Shri Shiva beheaded Yaksha Prajapati, lifted the charred body of Shri Sati and moved all over. It is said that the limbs of Shri Sati fell at 52 places and 52 Shakti Peethas came into existence. How sacrosanct, powerful and boon giving our N. Dham Ashram would be, where Sakshat Shri Adishakti reigns in Her full form ( Sakar and Nirakar) ? It is for all the S.Yogis to realize by their own Chaitanya Chetna. To quote from Her Guru puja speech :|” Today is the day I declare that I am the one who has to save the humanity. I declare that I am the one who is Adishakti, who is the Mother of all the Mothers, who is Primordial Mother, the Adishakti………….. I was the one who was born again and again, but now I in My complete form and with complete powers I have come on this earth, not only for salvation of human beings, not only for their emancipation, but for granting them the Kingdom of Heavens…….”|- Her Holiness Shree Mataji Nirmala Devi, London, 2nd December 1979.|Nirmal Dham, as such, is not only the Adishakti Peetha, it is ‘Sampoorna Adishakti Peetha’.`,
        location: {
          address:
            "Nirmal Dham (Behind BSF camp) Village Chhawla (Najafgarh), New Delhi 110071",
          contact: "+91 11253 16868",
          nearestRailwayStation: "Delhi",
          nearestAirport: "Indira Gandhi International Airport, Delhi",
          website: "https://www.nirmaldham.org",
          mapUrl:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.2669558172774!2d77.0186733160009!3d28.56174539403007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1a862cc7d953%3A0x105eabce451b8f58!2sNirmal%20Dham!5e0!3m2!1sen!2sin!4v1665827000230!5m2!1sen!2sin",
        },
      },
      {
        img: require("../../assets/holy_place_2.png"),
        name1: "Nirmal Nagari",
        name2: "Ganpatipule",
        description: `Ganapatipule is a Holy place where Mother Earth herself has created the Swayambhu (self originated idol) of Lord Ganesha. The name Ganapatipule is derived from ‘Ganapati’ (the Lord of Ganas) and Pule means Sand Dune. In ancient scriptures this Siddhi Vinayaka has been described as Paschim DwarDevta (Western Sentinel God). It is nearly 4000 years old Swayambhu. Surrounded by evergreen ranges of Sahayadri Mountains and an endless stretch of unpolluted silver sand, lined with innumerable mango, cashew, coconut and casuarina trees, Ganapatipule is a very picturesque sight. It is located in Ratnagiri District in the coastal area of Konkan region and situated at about 35 Kms from Ratnagiri Railway Station.
            “Ganapatipule has a special significance – because He is Mahaganesha. The Ganesha at Mooladhara becomes Mahaganesha in the Virata, that is the brain . . . The main root, as they call the taproot, the main root is innocence, is Shri Ganesha. And He becomes Mahaganesha in Ganapatipule. . . Here He’s surrounded by the Father Principle, the Guru Principle of the sea, the ocean – the Indian Ocean. That is, here He becomes a Guru . . . Here, as we see in Ganapatipule, Shri Ganesha is in its complete matured form; so that we develop also our Mahaganesha within ourselves . . . Today we came to Ganapatipule, it has a great significance, among the Asthavinayaka – eight Swayambhus of Shri Ganesha). It is the place of Mahaganesha. Mahaganesha is Pithadhish (Governor of all pithas) and sitting on this pitha, he looks after (protects) all the Ganesha Swayambhus . . “
            – Her Holiness Shree Mataji Nirmala Devi, Shri Mahaganesha Puja. Ganapatipule (India), 1 January 1986.
            “I had a special purpose in coming to Ganapatipule, apart from that it was a very beautiful place and very relaxing for all of you. The reason is: I found this place had vibrations which will clear you out very easily. . “
            – Her Holiness Shree Mataji Nirmala Devi, The innocence of a child, Ganpatipule (India), 31 December 1985.
            “This seminar, or whatever you may call it, Ganapatipule, I don’t know what you call is a Divine congregation of all the Gods and Goddesses, is very symbolic to Me, because this represents the whole world, it represents the problems of the whole world and also the solutions. It’s such a beautiful idea. I don’t know how it worked into My head that I started this kind of a beautiful congregation to meet you all.”
            – Her Holiness Shree Mataji Nirmala Devi, Talk about Shri Jesus. Ganapatipule (India), 24 December 1990.`,
        significance: `More than 20 Sakar pujas with Shri Mataji, more than 45 Talks and public programmes of Shri Mataji, during 1985 to 2003
            International Sahaja Yoga seminar and Christmas Puja, Sahaja marriages in December every year`,
        location: {
          address:
            "Nirmal Nagari, Arrey Warrey Road, Malgund-Bhailadi, Malgund Ratnagiri, Maharashtra 415615",
          contact: "+91 2357 235662, +91 2357 235663",
          nearestRailwayStation: "Ratnagiri",
          nearestAirport:
            "Chatrapati Shivaji Terminal International Airport(T2), Mumbai",
          mapUrl:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3811.754568267402!2d73.25492021592002!3d17.18214611321205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bea1adcc38a111b%3A0x375837b08067984a!2sNirmal%20Nagari%2C%20Sahaja%20Yoga%2C%20Ganapatipule!5e0!3m2!1sen!2sin!4v1665827200672!5m2!1sen!2sin",
        },
      },
      {
        img: require("../../assets/holy_place_3.png"),
        name1: "International Sahaja Yoga",
        name2: "Research & Health Center C B D",
        description: `Her Holiness Shree Mataji Nirmala Devi appointed the late Prof. Dr. U. C. Rai as the first Director of the Health Centre. Prof. Dr. U. C. Rai had worked as Head of the Physiology Department of Jawaharlal Institute of Postgraduate Medical Education and Research, Pondicherry, and as a Professor in various Medical colleges of Delhi. He had done research at the Lady Hardinge Medical College and associated hospitals, New Delhi, on the role of Sahaja Yoga in the treatment of psychosomatic diseases. He was conferred with the ‘Vladimir Verniczky Honoured Doctor’ award in Moscow, for his research work on Sahaja Yoga.
            The fundamental uniqueness of this Health Centre is to provide humanity with healing through vibrations generated by the practice of Sahaja Yoga. Prof Dr. U. C. Rai was of the opinion that through Sahaja Yoga, the results in the medical field were fantastic, especially in areas where doctor’s efforts had been ineffective.
            At the International Sahaja Yoga research & Health Centre, Shree Mataji’s Divine Grace has cured patients from all around the world. Sahaja Yogis across the globe especially from overseas countries like United States of America. Australia, United Kingdom, France. Switzerland, Austria, Africa, Malaysia, Singapore, Russia and Canada have visited on regular basis to get their subtle imbalances corrected as well as their ,physical ailments cured and have immensely benefited from the Sahaja Yoga Meditation and Sahaja Yoga techniques practices at the Health Centre.
            Cases of varied etiology such as Hypertension, Bronchial Asthma, Diabetes, Migraine, Epilepsy, Depression and Cancer have been cured at the Health Centre. The influx of number of patients visiting this Health Centre has risen progressively since 1996. A total of about 90,000 patients in-house admissions plus out patients, from all parts of the world have visited the Health Centre have benefited from the Sahaja method of treating the inner imbalances and thereby getting cured.
            To be admitted to the Health Centre patients’ needs to send individual requests by fax, post or e-mail, along with a brief medical history or a brief account of subtle imbalance, to the doctor in charge. Subsequently, all information and communications for booking and reservations for room accommodation at the Health Centre are taken care of by the reception desk.`,
        significance: `Founded on February 19, 1996 by Her Holiness Shri Mataji, Located at Shri Mataji Nirmala Devi Marg. CBD Belapur, Navi  Mumbai
            A True ‘gift to humanity’ personally established and developed under personal care and supervision of Her Holiness Shri Mataji Nirmala Devi for Health care and cure
            A unique centre of its kind in the world, where treatment is done only by vibratory awareness and Sahaja Yoga practices  as told by Shri Mataji directly
            Thousands of sahajis from all over the world get cured under the supervision of sahaja doctors`,
        location: {
          address:
            "Plot No. 1, Sector 8, Cbd Belapur, H.H Shri Mataji Nirmala Devi Marg, CBD Belapur, Navi Mumbai, Maharashtra 400614",
          contact: "+91 22 27576922, 27576410, +91 7738111184",
          nearestRailwayStation: "Mumbai",
          nearestAirport: "Mumbai",
          website: "https://www.sahajahealthcentre.com",
          mapUrl:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.7658189483586!2d73.03774211592918!3d19.03003815835836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3b535fd037f%3A0x135a7312f6ca3990!2sInternational%20Sahaja%20Yoga%20Research%20and%20Health%20Centre!5e0!3m2!1sen!2sin!4v1665827247642!5m2!1sen!2sin",
        },
      },
      {
        img: require("../../assets/holy_place_4.png"),
        name1: "Nirmal Dham Ashram",
        name2: "Avadgaon",
        description: `Rahuri has special recognition in Sahaja Yoga and it’s well-known over the world. The family of Her Holiness Shri Mataji’s father is from village Shingve Naik and whereas Her mother’s and grand mother’s family is from village Nandgaon. Both these villages are on the border of Rahuri tehsil and at a distance of two km from each other. While talking about Rahuri, Shri Mataji always used to say that it is her maika [mother’s place]. Thus she had tremendous love for Rahuri.
            On 22nd and 23rd march 1975, she came to the agricultural university at Rahuri to give self realization to the staff and students of the university. During these public programs, Her Holiness Shri Mataji used to give self realization enmasse to the villagers. Besides villages Shingve and Nandgaon being her parents place,  villages namely, Musalwadi, Avadgaon(Aradgaon) and Taklimiya occupy important place in Sahaja Yoga for various spiritual reasons. Rahuri used to be the important visiting place during India tours of Sahajayogis coming from abroad. Perhaps the first marriage in Sahaja Yoga was held in Rahuri. Her Holiness Shri Mataji had suggested to the villagers of Aradgaon to change the name of village as Avadgaon as She liked this place.
            “This is the place where My forefathers reigned as kings and they had a dynasty also. Now, the other place we went to, Musalwadi, is the place where the Goddess first of all hit him (Rahu the demon) with a beam so it is called as Musalwadi. It means a beam. Musol means a beam. So this is a place where Goddess has done a lot of work. There is another place called Aradgaon where he was running and he was screaming, so it’s called as arad, ‘arad’ means ‘screaming’ (gaon means village). So the whole place is already very much vibrated because also the Nathas, the nine Nathas, we saw one Ganivnath there, but all of them lived in this area, worked very hard. The last of all, Sainath, was very near here, as you know, in Shirdi. So this is a very, very holy place and a place of great worship where many a times the Goddess was worshipped. Rahuri itself, if you go round, you find there are 9 deities sitting there. These are also created by Mother Earth, they are beautiful things. No-one knows about them. You can go and have a look. There are 9 of them and these represent the 9 incarnations of the Goddess, or 9 styles of the Goddess. Now, in this beautiful place we are here, though it is spoiled by many things, but, as you can see, it’s very comfortable for our Spirit to be here.”
            – Her Holiness Shree Mataji Nirmala Devi, Talk about leadership, Rahuri, Maharashtra, India, 22nd January 1985.`,
        significance: `The Aradgaon Nirmal Dham Ashram on about 7 acres of land was established in 1987
            Sakar Pujas and India tours with Shri Mataji’s for 11 years from 1975 to 1985
            Ekadesha  Rudra Swayambhu Shilanyas done by Her Holiness Shri Mataji in 1982 at Musalwadi
            Sahaja Women’s Welfare Society was also founded by Her Holiness Shri Mataji at Avadgaon in 1987`,
        location: {
          address:
            "Nirmaldham Ashram, Avadgoan (Aradgaon), Tal. Rahuri, District Ahmednagar",
          contact:
            "+91 8871519191, +91 9923563111, +91 9423785754, +9130005937, +91 9511371117, +91 9322249526, +91 9890897916",
          mapUrl:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.6724781256116!2d74.69931851593131!3d19.426552245905498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdc910ed8f3f48f%3A0xcaad63a03df2e75c!2sNirmaldham%20ashram%2C%20Aardgaon!5e0!3m2!1sen!2sin!4v1665827309587!5m2!1sen!2sin",
        },
      },
      {
        img: require("../../assets/holyplace-vaitarna.jpg"),
        name1: "Shri P. K. Salve Kala",
        name2: "Pratisthan Vaitarna Academy",
        description: `Her Holiness Shri Mataji Nirmala Devi established Shri P. K. Salve Kala Pratishthan, an academy of Indian Classical Music and Fine Arts, at Vaitarna. 
            Vaitarna is surrounded by Dams and Lakes in the vicinity of scenic greenery, is about 100 kms from Mumbai and about 80 kms from Nashik off National Highway 3 (Mumbai Agra Highway), and accessible from Khardi Railway Station (25kms) of the Central Railways.
            “Every country has its own problems, but we have something very great with us is our music. Not musicians, but music. So the musicans should take to Sahaja Yoga. They should meditate. And if a musician is money-oriented, then you cannot help him. Either he should be music-oriented, and not money-oriented. When they are money-oriented, they never value themselves, I think. Because if you have music, the talent of music why should you care for money? And whatever money you give them, they’ll be never satisfied. I have seen very great musicians who were never, never money-oriented, who didn’t care for power. But we have even now many musicians who I would not say are the last word in music. Those who are, are very humble. They’ll always tell you. “We have to still learn a lot. We have to understand a lot.”
            – Her Holiness Shree Mataji Nirmala Devi, Inauguration speech for the opening of the new Music Academy , Vaitarna (India), 1 January 2003.`,
        significance: `The Institution is situated within an area of 62 acres of land comprising of streams and natural landscape. Modak Sagar and Tansa Dams are in the vicinity with a thick reserved forest cover.
            Students from diverse cultures across the globe, have experienced their untapped potential flourishing. Through their holistic understanding of Sahaja Yoga, one of the precious gifts by our holy Mother to the mankind, they have gained/achieved distinctive insight while learning music and art at the Academy.`,
        location: {
          address:
            "Near Vaitarna Dam, Village Belvad, Tal Sahapur, District Thane, Maharashtra 421304",
          nearestRailwayStation: "Khardi",
          nearestAirport:
            "Chatrapati Shivaji Terminal International Airport(T2), Mumbai",
          website: "https://www.pksacademy.com",
          mapUrl:
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3757.3169091060927!2d73.29084092806866!3d19.65648060242909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be77a6b6fbf7663%3A0x7965d01dcccc307d!2sP.%20K.%20Salvi%20Kala%20Prathishthan%20-%20Sahaja%20Yoga%20Academy!5e0!3m2!1sen!2sin!4v1665827354445!5m2!1sen!2sin",
        },
      },
    ];
    setHolyPlaces(places);
  }, []);

  return (
    <Box className={classes.homeContainer}>
      {/* holy place hero card */}
      <Box
        component="img"
        sx={{ width: 1 }}
        src={require("../../assets/holy_place_hero_card.png")}
        alt={"holy_place_hero_card"}
      />
      {/* holy place cards */}
      {holyPlaces.length && (
        <Box className={classes.cardContainer}>
          {holyPlaces.map((place) => (
            <HolyPlaceCard
              holyPlace={place}
              setOpen={setOpen}
              setSelectedHolyPlace={setSelectedHolyPlace}
            />
          ))}
        </Box>
      )}
      {isMobile ? (
        <MobileHolyPlaceModal
          open={open}
          setOpen={setOpen}
          selectedHolyPlace={selectedHolyPlace}
        />
      ) : (
        <HolyPlaceModal
          open={open}
          setOpen={setOpen}
          selectedHolyPlace={selectedHolyPlace}
        />
      )}
    </Box>
  );
};

export default HolyPlace;
