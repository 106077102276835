import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { appTheme } from "../../../styles/theme";
import { useMediaQuery, useTheme } from "@mui/material";

const useStyles = makeStyles({
  primaryButton: {
    background: appTheme.palette.primary.main,
    borderRadius: 5,
    boxShadow: "none",
    padding: `${appTheme.spacing(1)} ${appTheme.spacing(4)}`,
    "&:hover": { backgroundColor: appTheme.palette.primary.main },
  },
  mobilePrimaryButton: {
    background: appTheme.palette.primary.main,
    borderRadius: 5,
    boxShadow: "none",
    fontSize: 12,
    padding: `${appTheme.spacing(1)} ${appTheme.spacing(4)}`,
    "&:hover": { backgroundColor: appTheme.palette.primary.main },
  },
});

const PrimarySquareButton = (props) => {
  const classes = useStyles();
  const { children, className, onClick, variant = "contained" } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Button
      className={
        isMobile
          ? `${classes.mobilePrimaryButton} ${className}`
          : `${classes.primaryButton} ${className}`
      }
      variant={variant}
      disableRipple
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default PrimarySquareButton;
