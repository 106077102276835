import { Box, Typography, createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const PrivacyPolicy = () => {
  const theme = createTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //textAlign: "left",
        //mx: theme.spacing(4),
        //mt: theme.spacing(4),
        mb: theme.spacing(2),
        mt: theme.spacing(1),
        pl: "2%",
        pb: "2%",
      }}
    >
      <Typography variant="h3">Privacy Policy</Typography>
      <br />
      <div>
        <p>
          This privacy policy sets out how
          www.thelifeeternaltrustpune.org/nirmal-sahitya uses and protects any
          information that you give
          www.thelifeeternaltrustpune.org/nirmal-sahitya when you use this
          website. www.thelifeeternaltrustpune.org/nirmal-sahitya is committed
          to ensuring that your privacy is protected. Should we ask you to
          provide certain information by which you can be identified when using
          this website, then you can be assured that it will only be used in
          accordance with this privacy statement.
          www.thelifeeternaltrustpune.org/nirmal-sahitya may change this policy
          from time to time by updating this page. You should check this page
          from time to time to ensure that you are happy with any changes.
        </p>
        <h4>
          <strong>
            <b>What we collect</b>
          </strong>
        </h4>
        <p>We may collect the following information:</p>
        <ul>
          <li>name</li>
          <li>contact information including email address</li>
          <li>
            demographic information such as postcode, preferences and interests
          </li>
          <li>other information relevant to customer surveys and/or offers</li>
        </ul>
        <p>
          For the exhaustive list of cookies we collect see the List of cookies
          we collect section.
        </p>
        <h4>
          <strong>
            <b>What we do with the information we gather</b>
          </strong>
        </h4>
        <p>
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
        </p>
        <ul>
          <li>Internal record keeping.</li>
          <li>
            We may use the information to improve our products and services.
          </li>
          <li>
            We may periodically send promotional emails about new products,
            special offers or other information which we think you may find
            interesting using the email address which you have provided.
          </li>
          <li>
            From time to time, we may also use your information to contact you
            for market research purposes. We may contact you by email, phone,
            fax or mail. We may use the information to customise the website
            according to your interests.
          </li>
          <li>
            Without identifying you personally we may use your information to
            provide updates on our service offerings and promotional schemes
            through third party advertising partners (‘TPAP’). Our TPAP may use
            cookies on our website as well as third party websites and social
            media platforms to understand customer interests to provide updates
            on our latest service offerings and promotional schemes that are
            akin to your interests. Our TPAP provide you with complete control
            over ads experience and you can remove ads shown to you.
          </li>
        </ul>
        <h4>
          <strong>
            <b>Security</b>
          </strong>
        </h4>
        <p>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure, we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>
        <h4>
          <strong>
            <b>How we use cookies</b>
          </strong>
        </h4>
        <p>
          A cookie is a small file which asks permission to be placed on your
          computer’s hard drive. Once you agree, the file is added and the
          cookie helps analyse web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences.
        </p>
        <p>
          We use traffic log cookies to identify which pages are being used.
          This helps us analyse data about web page traffic and improve our
          website in order to tailor it to customer needs. We only use this
          information for statistical analysis purposes and then the data is
          removed from the system.
        </p>
        <p>
          Overall, cookies help us provide you with a better website, by
          enabling us to monitor which pages you find useful and which you do
          not. A cookie in no way gives us access to your computer or any
          information about you, other than the data you choose to share with
          us. You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website.
        </p>
        <h4>
          <strong>
            <b>Links to other websites</b>
          </strong>
        </h4>
        <p>
          Our website may contain links to other websites of interest. However,
          once you have used these links to leave our site, you should note that
          we do not have any control over that other website. Therefore, we
          cannot be responsible for the protection and privacy of any
          information which you provide whilst visiting such sites and such
          sites are not governed by this privacy statement. You should exercise
          caution and look at the privacy statement applicable to the website in
          question.
        </p>
        <h4>
          <strong>
            <b>Controlling your personal information</b>
          </strong>
        </h4>
        <p>
          You may choose to restrict the collection or use of your personal
          information in the following ways:
        </p>
        <ul>
          <li>
            We will not sell, distribute or lease your personal information to
            third parties unless we have your permission or are required by law
            to do so. We may use your personal information to send you
            promotional information about third parties which we think you may
            find interesting if you tell us that you wish this to happen.
          </li>
          <li>
            You may request details of personal information which we hold about
            you under the Data Protection Act 1998. A small fee will be payable.
            If you would like a copy of the information held on you please write
            to us at nirmalsahityasales@gmail.com
          </li>
          <li>
            If you believe that any information we are holding on you is
            incorrect or incomplete, please write to or email us as soon as
            possible, at the above address. We will promptly correct any
            information found to be incorrect.
          </li>
        </ul>
        <h4>Grievances</h4>
        <p>
          Should you have any grievances about the processing of your personal
          information or the privacy policy, you may contact:
        </p>
        <p>Phone: +91 9168763232</p>
        <p>Email: nirmalsahityasales@gmail.com</p>
        <p>&nbsp;</p>
        <p>{/* /wp:heading */}</p>{" "}
      </div>
    </Box>
  );
};

export default PrivacyPolicy;
