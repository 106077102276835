import React from "react";
import { Box, Button, Container, createTheme, Typography } from "@mui/material";
import { HolyPlaceModel } from "../../interfaces/interfaces";
import { makeStyles } from "@material-ui/styles";
import { appTheme } from "../../styles/theme";

type Props = {
  selectedHolyPlace: HolyPlaceModel;
};

const useStyles = makeStyles({
  informationTabContainer: {
    display: "flex",
    overflow: "auto",
  },
  informationContent: {
    margin: `${appTheme.spacing(4)}`,
  },
});

function InformationTab({ selectedHolyPlace }: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.informationTabContainer}>
      <Box
        component="img"
        sx={{ width: "40%", height: 1,marginTop: `${appTheme.spacing(4)}` }}
        src={selectedHolyPlace.img}
        alt={selectedHolyPlace.name1
          .concat(" ")
          .concat(selectedHolyPlace.name2)}
      />
      <Box className={classes.informationContent}>
        <Typography variant="h4">{selectedHolyPlace.name1}</Typography>
        <Typography variant="h4" sx={{ mb: appTheme.spacing(2) }}>
          {selectedHolyPlace.name2}
        </Typography>
        {selectedHolyPlace.description.split("|").map((desc) => (
          <>
            <Typography>{desc}</Typography>
            <Box component={"br"} />
          </>
        ))}
      </Box>
    </Box>
  );
}

export default InformationTab;
