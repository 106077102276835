import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SOCIALS, Urls } from "../../interfaces/constants";
import HeaderButton from "../header/HeaderButton";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { makeStyles } from "@material-ui/core/styles";
import { appTheme } from "../../styles/theme";

const useStyles = makeStyles({
  footer: {
    display: "flex",
    justifyContent: "space-evenly",
    background: appTheme.palette.secondary.main,
    padding: "1rem 1rem",
  },
  footerLink: {
    color: appTheme.palette.primary.main,
    fontWeight: 500,
    "&:hover": {
      cursor: "pointer",
    },
  },
  externalLink: {
    color: appTheme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  socialContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  socialIcons: {
    display: "flex",
    justifyContent: "center",
  },
});

const WebFooter = ({
  footerLinks,
  externalLinks,
  handleClickOnHeaderButton,
  privacyPolicy,
  pujas,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  return (
    <Box className={classes.footer} component="footer">
      <Box>
        <Box className={classes.socialContainer}>
          <Box
            component="img"
            src={require("../../assets/sy-logo.png")}
            maxWidth={appTheme.spacing(32)}
          />
          <HeaderButton
            className={classes.footerLink}
            variant="text"
            color={"info"}
            disableRipple
            onClick={() => navigate(Urls.Homepage)}
          >
            {t("header.theLifeEternalTrustPune")}
          </HeaderButton>
        </Box>
        <Box className={classes.socialIcons}>
          {/* <InstagramIcon htmlColor="#fb3958" /> */}
          <FacebookIcon
            cursor={"pointer"}
            htmlColor="#3b5998"
            onClick={() => {
              window.open(SOCIALS.FaceBook);
            }}
          />
          <YouTubeIcon
            cursor={"pointer"}
            htmlColor="#c4302b"
            sx={{ mx: appTheme.spacing(4) }}
            onClick={() => {
              window.open(SOCIALS.Youtube);
            }}
          />
          <WhatsAppIcon
            cursor={"pointer"}
            htmlColor="#4FCE5D"
            onClick={() => {
              window.open(SOCIALS.WhatsApp);
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "start",
        }}
      >
        <Typography
          className={classes.footerLink}
          onClick={() => {
            window.open("founder", "_self");
          }}
        >
          Founder
        </Typography>
        <Typography
          className={classes.footerLink}
          onClick={() => {
            window.open("holy-places", "_self");
          }}
        >
          Holy Places
        </Typography>
        <Typography
          className={classes.footerLink}
          onClick={() => {
            window.open("free-classes", "_self");
          }}
        >
          Free Classes
        </Typography>
        <Typography
          className={classes.footerLink}
          onClick={() => {
            window.open("contact-us", "_self");
          }}
        >
          Contact Us
        </Typography>
        <Typography
          className={classes.footerLink}
          onClick={() => {
            window.open("donation", "_self");
          }}
        >
          Donation
        </Typography>
        <Typography
          className={classes.footerLink}
          onClick={() => {
            window.open("sahaja-material", "_self");
          }}
        >
          Sahaja Material
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.footerLink}>
          {t("labels.sahajayogaLinks")}
        </Typography>
        {externalLinks.map((link) => (
          <Typography
            className={classes.externalLink}
            onClick={() => {
              window.open(link);
            }}
          >
            {link.replace("https://", "")}
          </Typography>
        ))}
      </Box>
      <Box>
        <Typography className={classes.footerLink}>
          {t("labels.upcomingEvents")}
        </Typography>
        <Typography
          className={classes.externalLink}
          onClick={() =>
            navigate(Urls.Events, {
              state: { activeTab: "upcomingPuja", activeIndex: 1 },
            })
          }
        >
          {
            pujas?.find(
              (puja) =>
                new Date() < new Date(puja.puja_date) && puja.type === "puja"
            )?.puja_name
          }
        </Typography>
        <Typography
          className={classes.footerLink}
          onClick={() => {
            window.open("terms-and-conditions");
          }}
        >
          {t("footer.termsCondition")}
        </Typography>
        <Typography
          className={classes.footerLink}
          onClick={() => {
            window.open("shipping-policy");
          }}
        >
          Shipping Policy
        </Typography>
        <Typography
          className={classes.footerLink}
          onClick={() => {
            window.open("refund-policy");
          }}
        >
          Refund Policy
        </Typography>
        <Typography
          className={classes.footerLink}
          onClick={() => {
            window.open("privacy-policy");
          }}
        >
          Privacy Policy
        </Typography>
      </Box>
      {location.pathname === Urls.SMHomepage && (
        <Box>
          <Typography
            className={classes.footerLink}
            onClick={() => {
              window.open("terms-and-conditions");
            }}
          >
            {t("footer.termsCondition")}
          </Typography>
          <Typography
            className={classes.footerLink}
            onClick={() => {
              window.open("shipping-policy");
            }}
          >
            Shipping Policy
          </Typography>
          <Typography
            className={classes.footerLink}
            onClick={() => {
              window.open("refund-policy");
            }}
          >
            Refund Policy
          </Typography>
          <Typography
            className={classes.footerLink}
            onClick={() => {
              window.open("privacy-policy");
            }}
          >
            Privacy Policy
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default WebFooter;
