import React, { useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { appTheme } from "../../styles/theme";
import { useNavigate, useLocation } from "react-router-dom";
import { Urls } from "../../interfaces/constants";
import MyAccount from "./MyAccount";

const useStyles = makeStyles({
  buttonIcon: {
    flex: 1,
    paddingLeft: appTheme.spacing(2),
    paddingBottom: appTheme.spacing(1),
  },
  menuButtonIcon: {
    marginLeft: "auto",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    marginBottom: appTheme.spacing(2),
  },
});

const AppDrawer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const pages = [
    "header.freeClasses",
    "header.holyPlaces",
    "header.events",
    "header.founder",
    "header.contactUs",
    "header.donation",
    "header.sahajaMaterial",
  ];

  const handleClickOnHeaderButton = (index) => {
    if (index !== -1) {
      switch (index) {
        case 0:
          navigate(Urls.FreeClasses);
          break;
        case 1:
          navigate(Urls.HolyPlace);
          break;
        case 2:
          navigate(Urls.Events);
          break;
        case 3:
          navigate(Urls.Founder);
          break;
        case 4:
          navigate(Urls.ContactUs);
          break;
        case 5:
          window.open(Urls.Donation);
          break;
        case 6:
          navigate(Urls.SMHomepage);
          break;
        default:
          navigate(Urls.Homepage);
      }
      setOpenDrawer(false);
    }
  };
  const location = useLocation();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box>{location.pathname.includes(Urls.SMHomepage) && <MyAccount />}</Box>
      <IconButton
        className={classes.menuButtonIcon}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="secondary" />
      </IconButton>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: { width: 260 },
        }}
      >
        <List className={classes.listContainer}>
          <ListItemButton key={150}>
            {location.pathname.includes(Urls.SMHomepage) && <MyAccount />}
          </ListItemButton>
          <ListItemButton key={100}>
            <ListItemIcon className={classes.buttonIcon}>
              <ListItemText>
                <Typography
                  color={theme.palette.secondary.main}
                  variant="subtitle2"
                  onClick={() => {
                    navigate(Urls.Homepage);
                    setOpenDrawer(false);
                  }}
                >
                  {t("header.theLifeEternalTrustPune")}
                </Typography>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>
          <Divider className={classes.divider} />
          {pages.map((page, index) => (
            <ListItemButton key={index}>
              <ListItemIcon className={classes.buttonIcon}>
                <ListItemText>
                  <Typography
                    color={theme.palette.secondary.main}
                    variant="subtitle2"
                    onClick={() => handleClickOnHeaderButton(index)}
                  >
                    {t(page)}
                  </Typography>
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default AppDrawer;
