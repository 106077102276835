import FreeClasses from "./pages/FreeClasses";
import HolyPlace from "./pages/holyplace/HolyPlace";
import ContactUs from "./pages/ContactUs";
import Events from "./pages/events/Events";
import Home from "./pages/Home";
import SMHome from "./pages/sahaj-material/SMHome";
import Founder from "./pages/founder/Founder";
import Shop from "./pages/sahaj-material/Shop";
import ProductDetails from "./pages/sahaj-material/ProductDetails";
import Cart from "./pages/cart/Cart";
import Checkout from "./pages/checkout/Checkout";
import Verify from "./pages/verify/Verify";
import TermsAndConditions from "./pages/policies/TermsAndConditions";
import ShippingPolicy from "./pages/policies/ShippingPolicy";
import RefundPolicy from "./pages/policies/RefundPolicy";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";

const allRoutes: { [key: string]: any } = {
  "/": {
    component: Home, // Home
    label: "Home",
    parentNode: null,
    // isNavigationMenuItem: true
  },
  "/sahaja-material": {
    component: SMHome,
    label: "SMHome",
    parentNode: null,
    // isNavigationMenuItem: true
  },
  "/founder": {
    component: Founder,
    label: "Founder",
    parentNode: null,
  },
  "/free-classes": {
    component: FreeClasses,
    label: "FreeClasses",
    parentNode: null,
  },
  "/holy-places": {
    component: HolyPlace,
    label: "HolyPlace",
    parentNode: null,
    // isNavigationMenuItem: true
  },
  "/contact-us": {
    component: ContactUs,
    label: "ContactUs",
    parentNode: null,
  },
  "/events": {
    component: Events,
    label: "Events",
    parentNode: null,
  },
  "/sahaja-material/shop": {
    component: Shop,
    label: "Shop",
    parentNode: null,
  },
  "/sahaja-material/product/:productId": {
    component: ProductDetails,
    label: "Product",
    parentNode: null,
  },
  "/sahaja-material/cart": {
    component: Cart,
    label: "Cart",
    parentNode: null,
    requireAuth: true,
  },
  "/sahaja-material/checkout": {
    component: Checkout,
    label: "Checkout",
    parentNode: null,
    requireAuth: true,
    accessible: false,
  },
  "/sahaja-material/checkout/verify": {
    component: Verify,
    label: "Verify",
    parentNode: null,
  },
  "/terms-and-conditions": {
    component: TermsAndConditions,
    label: "TermsAndConditions",
    parentNode: null,
  },
  "/shipping-policy": {
    component: ShippingPolicy,
    label: "ShippingPolicy",
    parentNode: null,
  },
  "/refund-policy": {
    component: RefundPolicy,
    label: "RefundPolicy",
    parentNode: null,
  },
  "/privacy-policy": {
    component: PrivacyPolicy,
    label: "PrivacyPolicy",
    parentNode: null,
  },
};

export default allRoutes;
