import axios, { Method, AxiosRequestHeaders } from "axios";
import { getSessionStorage } from "../utils/sessionStorage";

export const instance = axios.create({
  baseURL:
    "https://thelifeeternaltrustpune.org/let-pune-backend/let-pune-backend/public/api",
  timeout: 300000,
  headers: {},
});

const request = (
  method: Method,
  url: string,
  data: object,
  headers?: AxiosRequestHeaders
) => {
  return new Promise((resolve, reject) => {
    (() => {
      if (method === "get") {
        return instance.request({
          url,
          method,
          params: data,
          headers: {},
        });
      } else {
        return instance.request({
          url,
          method,
          data,
          headers: headers,
        });
      }
    })()
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err && err.response) {
          reject(err.response);
        } else {
          reject(err);
        }
      });
  });
};

export default {
  get: (endpoint: string, data: object = {}) => {
    return request("get", endpoint, data);
  },
  post: (
    endpoint: string,
    data: object = {},
    headers?: AxiosRequestHeaders
  ) => {
    return request("post", endpoint, data, {
      "Content-type": "application/json; charset=UTF-8",
      ...headers,
    });
  },
  postAuth: (
    endpoint: string,
    data: object = {},
    headers?: AxiosRequestHeaders
  ) => {
    return request("post", endpoint, data, {
      "Content-type": "application/json; charset=UTF-8",
      token: getSessionStorage("token"),
      ...headers,
    });
  },
};
