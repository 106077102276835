import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../components/common/Button/PrimaryButton";
import Notification, {
  AlertColor,
} from "../../components/common/Notification/Notification";
import PrimarySquareButton from "../../components/common/Button/PrimarySquareButton";
import {
  Address,
  createAddress,
  Product,
  Order,
  createOrder,
} from "../../services/let-data-services";

type Props = {};

function Checkout({}: Props) {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [notification, setNotification] = useState<{
    title: string;
    severity: AlertColor;
  }>(null);
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [fname, setFname] = useState<string>("");
  const [lname, setLname] = useState<string>("");
  const [companyName, setComapnyName] = useState<string>("");
  const [streetAddr, setStreetAddr] = useState<string>("");
  const [apartmentAddr, setApartmentAddr] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [pinCode, setPinCode] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [centerName, setCenterName] = useState<string>("");
  const [address, setAddress] = useState<Address>(null);
  const [addressError, setAddressError] = useState<string>("");
  const [addressLoading, setAddressLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<{
    order?: Order;
    payment_link?: string;
    order_total?: string;
  }>(null);
  const [orderError, setOrderError] = useState<string>("");
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [cart, setCart] = useState<Product[]>([]);
  const [charges, setCharges] = useState<string>("");
  const [subTotal, setSubTotal] = useState<string>("");
  const [total, setTotal] = useState<string>("");
  const [loadingPaymentLink, setLoadingPaymentLink] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  useEffect(() => {
    if (location?.state) {
      location.state.cart && setCart(location.state.cart);
      location.state.charges && setCharges(location.state.charges);
      location.state.subTotal && setSubTotal(location.state.subTotal);
      location.state.total && setTotal(location.state.total);
      location.state.pincode && setPinCode(location.state.pincode);
    }
  }, [location]);

  const handleTextChange = useCallback((event, key) => {
    const value = event.target.value;
    if (value) {
      switch (key) {
        case "fname":
          setFname(value);
          break;
        case "lname":
          setLname(value);
          break;
        case "cname":
          setComapnyName(value);
          break;
        case "sAddr":
          setStreetAddr(value);
          break;
        case "aAddr":
          setApartmentAddr(value);
          break;
        case "city":
          setCity(value);
          break;
        case "state":
          setState(value);
          break;
        case "pinCode":
          setPinCode(value);
          break;
        case "phone":
          setPhone(value);
          break;
        case "email":
          setEmail(value);
          break;
        case "centerName":
          setCenterName(value);
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case "fname":
          setFname("");
          break;
        case "lname":
          setLname("");
          break;
        case "cname":
          setComapnyName("");
          break;
        case "sAddr":
          setStreetAddr("");
          break;
        case "aAddr":
          setApartmentAddr("");
          break;
        case "city":
          setCity("");
          break;
        case "state":
          setState("");
          break;
        case "pinCode":
          setPinCode("");
          break;
        case "phone":
          setPhone("");
          break;
        case "email":
          setEmail("");
          break;
        case "centerName":
          setCenterName("");
          break;
        default:
          break;
      }
    }
  }, []);

  const orderCart = useCallback(
    (cart: Product[]) =>
      cart.map((product: Product) => {
        return { pid: product.pid, vid: product.vid, qty: product.qty };
      }),
    []
  );

  useEffect(() => {
    if (address?.id) {
      createOrder(
        fname,
        lname,
        phone,
        JSON.stringify(orderCart(cart)),
        address.id,
        pinCode,
        setOrder,
        setOrderLoading,
        setOrderError
      );
    }
  }, [address]);

  useEffect(() => {
    if (orderError) {
      setLoadingPaymentLink(false);
      setNotification({
        title:
          "Error Occured While Placing the Order. Please try after sometime",
        severity: "error",
      });
      setOpen(true);
    }
  }, [orderError]);

  useEffect(() => {
    if (order) {
      if (order.payment_link) {
        window.open(order.payment_link, "_self");
      }
    }
  }, [order]);

  const onPlaceOrder = useCallback(
    (fname, lname, phone, streetAddr, apartmentAddr, city, state, email) => {
      let flag = true;
      let temperrorMsg;

      const validMobile = new RegExp("^[0-9]{10}$");
      const validEmail = new RegExp(
        "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
      );

      if (fname === "") {
        flag = false;
        setError(true);
        temperrorMsg = "Please Enter First Name ";
      } else if (lname === "") {
        flag = false;
        setError(true);
        temperrorMsg = "Please Enter Last Name";
      } else if (!validMobile.test(phone)) {
        flag = false;
        setError(true);
        temperrorMsg = "Please Enter Valid Phone Number";
      } else if (streetAddr === "" || apartmentAddr === "") {
        flag = false;
        setError(true);
        temperrorMsg = "Please Enter Address";
      } else if (city === "") {
        flag = false;
        setError(true);
        temperrorMsg = "Please Enter City";
      } else if (state === "") {
        flag = false;
        setError(true);
        temperrorMsg = "Please Enter State";
      }

      if (flag) {
        setLoadingPaymentLink(true);
        setError(false);
        createAddress(
          streetAddr,
          apartmentAddr,
          city,
          state,
          pinCode,
          setAddress,
          setAddressLoading,
          setAddressError
        );
      } else {
        setNotification({ title: temperrorMsg, severity: "warning" });
        setOpen(true);
      }
    },
    [streetAddr, apartmentAddr, city, state, pinCode]
  );

  return (
    <Box sx={isMobile ? {} : { display: "flex" }}>
      {notification && (
        <Notification
          open={open}
          setOpen={setOpen}
          severity={notification.severity}
        >
          {notification.title}
        </Notification>
      )}
      {loadingPaymentLink && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingPaymentLink}
        >
          <CircularProgress />
        </Backdrop>
      )}

      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: "1rem",
          mx: theme.spacing(6),
        }}
      >
        <Typography fontWeight={"bold"} sx={{ my: theme.spacing(4) }}>
          Shipping details
        </Typography>
        <Box sx={{ display: "flex" }}>
          <TextField
            sx={{ display: "flex", flex: 1, mr: theme.spacing(2) }}
            label="First name"
            value={fname}
            onChange={(e) => handleTextChange(e, "fname")}
          />
          <TextField
            sx={{ display: "flex", flex: 1 }}
            label="Last name"
            value={lname}
            onChange={(e) => handleTextChange(e, "lname")}
          />
        </Box>
        <TextField
          label="Apartment, suite, unit, etc"
          value={streetAddr}
          onChange={(e) => handleTextChange(e, "sAddr")}
        />
        <TextField
          label="Street Address"
          value={apartmentAddr}
          onChange={(e) => handleTextChange(e, "aAddr")}
        />
        <TextField
          label="Town/ City"
          value={city}
          onChange={(e) => handleTextChange(e, "city")}
        />
        <TextField
          label="State/ Country"
          value={state}
          onChange={(e) => handleTextChange(e, "state")}
        />
        <TextField
          label="Postcode/ ZIP"
          value={pinCode}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Phone"
          value={phone}
          onChange={(e) => handleTextChange(e, "phone")}
          sx={{ pb: "1%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          mx: theme.spacing(6),
        }}
      >
        <Typography fontWeight={"bold"} sx={{ my: theme.spacing(6) }}>
          Your order
        </Typography>
        <Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography
              sx={{
                flex: 1,
                py: theme.spacing(4),
                px: theme.spacing(4),
                fontWeight: "bold",
              }}
            >
              Product
            </Typography>
            <Typography
              sx={{
                flex: 1,
                textAlign: "end",
                pr: theme.spacing(2),
                py: theme.spacing(4),
                px: theme.spacing(4),
                fontWeight: "bold",
              }}
            >
              Total
            </Typography>
          </Box>
          {cart?.map((product) => (
            <Box
              sx={{
                display: "flex",
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography
                sx={{
                  flex: 1,
                  py: theme.spacing(4),
                  px: theme.spacing(4),
                }}
              >
                {product.pname} X {product.qty}
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  textAlign: "end",
                  pr: theme.spacing(2),
                  py: theme.spacing(4),
                  px: theme.spacing(4),
                }}
              >
                &#8377;&nbsp;{Number(product.price) * Number(product.qty)}
              </Typography>
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              borderBottom: `1px solid ${theme.palette.divider}`,
              mt: theme.spacing(12),
            }}
          >
            <Typography
              sx={{
                flex: 1,
                background: "#BFD7ED",
                py: theme.spacing(4),
                px: theme.spacing(4),
                fontWeight: "bold",
              }}
            >
              Subtotal
            </Typography>
            <Typography
              sx={{
                flex: 1,
                textAlign: "end",
                pr: theme.spacing(2),
                py: theme.spacing(4),
                px: theme.spacing(4),
              }}
            >
              &#8377;&nbsp;{subTotal}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography
              sx={{
                flex: 1,
                background: "#BFD7ED",
                py: theme.spacing(4),
                px: theme.spacing(4),
                fontWeight: "bold",
              }}
            >
              Shipping
            </Typography>
            <Typography
              sx={{
                flex: 1,
                textAlign: "end",
                pr: theme.spacing(2),
                py: theme.spacing(4),
                px: theme.spacing(4),
              }}
            >
              &#8377;&nbsp;{charges}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography
              sx={{
                flex: 1,
                background: "#BFD7ED",
                py: theme.spacing(4),
                px: theme.spacing(4),
                fontWeight: "bold",
              }}
            >
              Packaging charges
            </Typography>
            <Typography
              sx={{
                flex: 1,
                textAlign: "end",
                pr: theme.spacing(2),
                py: theme.spacing(4),
                px: theme.spacing(4),
              }}
            >
              &#8377;&nbsp;{Number(subTotal) * 0.2}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                flex: 1,
                background: "#BFD7ED",
                py: theme.spacing(4),
                px: theme.spacing(4),
                fontWeight: "bold",
              }}
            >
              Total
            </Typography>
            <Typography
              sx={{
                flex: 1,
                textAlign: "end",
                pr: theme.spacing(2),
                py: theme.spacing(4),
                px: theme.spacing(4),
                fontWeight: "bold",
              }}
            >
              &#8377;&nbsp;{total}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              sx={
                isMobile
                  ? {
                      background: theme.palette.primary.main,
                      borderRadius: 5,
                      boxShadow: "none",
                      fontSize: 12,
                      padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                      },
                      width: 1,
                      my: theme.spacing(8),
                    }
                  : {
                      background: theme.palette.primary.main,
                      borderRadius: 5,
                      boxShadow: "none",
                      padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                      },
                      width: 1,
                      my: theme.spacing(8),
                    }
              }
              variant={"contained"}
              disableRipple
              onClick={() =>
                onPlaceOrder(
                  fname,
                  lname,
                  phone,
                  streetAddr,
                  apartmentAddr,
                  city,
                  state,
                  email
                )
              }
            >
              Place order
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Checkout;
