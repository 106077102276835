import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useMediaQuery, useTheme } from "@mui/material";

export type AlertColor = "success" | "info" | "warning" | "error";

type Props = {
  severity: AlertColor;
  children?: any;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { open, setOpen } = props;
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={props.severity}
        sx={isMobile ? { width: "50%" } : { width: "100%" }}
      >
        {props.children}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
