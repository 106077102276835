import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Button, Container, createTheme, Typography } from "@mui/material";
import { HolyPlaceModel } from "../../interfaces/interfaces";
import { appTheme } from "../../styles/theme";

type Props = {
  selectedHolyPlace: HolyPlaceModel;
};

const useStyles = makeStyles({
  significanceTabContainer: {
    display: "flex",
    flexDirection: "column",
    margin: `${appTheme.spacing(4)}`,
  },
});

function SignificanceTab({ selectedHolyPlace }: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.significanceTabContainer}>
      {selectedHolyPlace.significance.split("|").map((desc) => (
        <>
          <Typography>{desc}</Typography>
          <Box component={"br"} />
        </>
      ))}
    </Box>
  );
}

export default SignificanceTab;
