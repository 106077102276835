import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import React from "react";
import { useTranslation } from "react-i18next";
import { appTheme } from "../styles/theme";

const image: { label: string; imgPath: string } = {
  label: "Image1",
  imgPath: "images/Banner-1.png",
};

const useStyles = makeStyles((theme) => ({
  grid: {
    width: "80%",
    margin: "auto",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  map: {
    border: "0px",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    marginTop: "2%",
    marginBottom: "2%",
  },
  flexContainerMobile: {
    display: "flex",
    flexDirection: "column",
  },
  flexItem: {
    flex: "50%",
    padding: "1%",
  },
}));
const ContactUs = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Box
        component="img"
        sx={{ width: 1 }}
        src={require("../assets/holy_place_hero_card.png")}
        alt={"holy_place_hero_card"}
      />
      <Box
        className={
          isMobile ? classes.flexContainerMobile : classes.flexContainer
        }
        sx={{ margin: "auto" }}
      >
        <Box className={classes.flexItem}>
          <Typography sx={{ pl: `${appTheme.spacing(8)}` }}>
            <h2>For The Life Eternal Trust Pune:-</h2>
            Address:- Sahajayoga Dhyan Kendra Pune, Bhusari Colony, Kothrud,
            Pune - 411038<br></br>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LocalPhoneIcon sx={{ mr: appTheme.spacing(2) }} />
            <Typography sx={{ textDecoration: "underline" }}>
              {t("contactUs.contactNumberTitle")}
            </Typography>
          </Box>
          <Typography sx={{ pl: `${appTheme.spacing(8)}` }}>
            {t("contactUs.contactNumber")}
          </Typography>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60534.74855379946!2d73.73327688163843!3d18.509832376830047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be4e829825b3%3A0x5adcd2eb1c488c3f!2sSahaja%20Yoga%20Dhyankendra-%20Pune%20Main%20Centre!5e0!3m2!1sen!2sin!4v1665597248386!5m2!1sen!2sin"
            width="100%"
            height={isMobile ? "100%" : "400"}
            loading="lazy"
            className={classes.map}
          ></iframe>
        </Box>
        <Box className={classes.flexItem}>
          <Typography sx={{ pl: `${appTheme.spacing(8)}` }}>
            <h2>For Vishwa Nirmala Dharma:-</h2>
            Address:- Office No. 101, Sr. No. 264/6, Santrkrupa Bldg, Baner
            Road, Above Axis Bank ATM, Baner, Pune -411045<br></br>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LocalPhoneIcon sx={{ mr: appTheme.spacing(2) }} />
            <Typography sx={{ textDecoration: "underline" }}>
              {t("contactUs.contactNumberTitle")}
            </Typography>
          </Box>
          <Typography sx={{ pl: `${appTheme.spacing(8)}` }}>
            +91-7588115116
          </Typography>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15129.848132584271!2d73.8025776!3d18.5531973!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfae3c91bdc7%3A0x9ba7411cd3c915b!2sAxis%20Bank%20ATM!5e0!3m2!1sen!2sin!4v1707472181783!5m2!1sen!2sin"
            width="100%"
            height={isMobile ? "100%" : "400"}
            loading="lazy"
            className={classes.map}
          ></iframe>
        </Box>
      </Box>
    </div>
  );
};

export default ContactUs;
