import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import {
  fetchProductsByCategory,
  Product,
  Puja,
} from "../../services/let-data-services";
import { appTheme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Urls } from "../../interfaces/constants";
import PrimarySquareButton from "../../components/common/Button/PrimarySquareButton";

const useStyles = makeStyles({
  productPage: {
    margin: `${appTheme.spacing(8)} ${appTheme.spacing(12)}`,
  },
  mobileProductPage: {
    margin: `${appTheme.spacing(2)} ${appTheme.spacing(2)}`,
  },
  categoryToolbar: {
    display: "flex",
    alignItems: "flex-start",
  },
  categoriesDropdown: {
    width: "30%",
  },
  mobileCategoriesDropdown: {
    width: "55%",
  },
  divider: {
    border: `1px solid ${appTheme.palette.divider}`,
    marginTop: appTheme.spacing(4),
    marginBottom: appTheme.spacing(4),
  },
  productContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  productCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    margin: appTheme.spacing(2),
    borderRadius: 5,
  },
  mobileProductCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "44%",
    margin: appTheme.spacing(2),
    borderRadius: 5,
  },
  viewButton: {
    margin: appTheme.spacing(4),
  },
});

const Shop = () => {
  const clx = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState<Puja>(null);
  const [categoriesMenuOptions, setCategoriesMenuOptions] = useState<Puja[]>(
    []
  );
  const [products, setProducts] = useState<Product[]>(null);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsError, setProductsError] = useState(null);

  useEffect(() => {
    if (location?.state.selectedCategory) {
      setSelectedCategory(location.state.selectedCategory);
      setCategoriesMenuOptions(location?.state?.categories);
    }
  }, [location]);

  useEffect(() => {
    if (selectedCategory) {
      fetchProductsByCategory(
        selectedCategory.cat_name,
        setProducts,
        setProductsLoading,
        setProductsError
      );
    }
  }, [selectedCategory]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedCategory(
      categoriesMenuOptions.find(
        (option) => option.cat_name === event.target.value
      )
    );
  };

  const handleViewProduct = (product: Product) => {
    if (product && selectedCategory && categoriesMenuOptions && products) {
      navigate(Urls.ProductDetails.concat(`/${product.pid}`), {
        state: {
          product,
          products,
          selectedCategory: selectedCategory,
          categories: categoriesMenuOptions,
        },
      });
    }
  };

  return (
    <>
      {/* hero card */}
      <Box
        component="img"
        sx={{ width: 1 }}
        src={require("../../assets/holy_place_hero_card.png")}
        alt={"holy_place_hero_card"}
      />
      {/* product categories */}
      <Box className={isMobile ? clx.mobileProductPage : clx.productPage}>
        <Box className={clx.categoryToolbar}>
          <FormControl
            className={
              isMobile ? clx.mobileCategoriesDropdown : clx.categoriesDropdown
            }
          >
            <InputLabel>Categories</InputLabel>
            <Select
              value={selectedCategory ? selectedCategory.cat_name : ""}
              label="Categories"
              onChange={handleChange}
            >
              {categoriesMenuOptions?.map((category: Puja) => (
                <MenuItem key={category.id} value={category.cat_name}>
                  {category.cat_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={clx.divider} />
        <Box className={clx.productContainer}>
          <Typography textAlign="center" variant="h6">
            {selectedCategory
              ? selectedCategory.cat_name
              : t("smHome.sahajaMaterialCategoty")}
          </Typography>
          {/* product container */}
        </Box>

        <Box className={clx.productContainer}>
          {products?.map((product) => (
            <Paper
              className={isMobile ? clx.mobileProductCard : clx.productCard}
              elevation={4}
            >
              <Box
                component="img"
                sx={
                  isMobile
                    ? { width: 1, height: "10rem", objectFit: "contain" }
                    : { width: 1, height: "20rem", objectFit: "contain" }
                }
                src={product.image}
                alt={product.pname}
              />
              <Typography
                textAlign="center"
                variant={isMobile ? "subtitle2" : "h6"}
              >
                {product.pname}
              </Typography>
              <PrimarySquareButton
                className={clx.viewButton}
                onClick={() => handleViewProduct(product)}
              >
                {t("smHome.view")}
              </PrimarySquareButton>
            </Paper>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Shop;
